import { Select } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CarrierSelection } from '../../../../../carrier-configuration/components/add-carrier-flow/carrierAccountData'
import { ValidationContainer } from '../../../../../common/components/ValidationContainer'
import { ValidationResult } from '../../../../../common/helpers/validation'
import { Option, reactSelectPortalId } from '../../../../../common/models'
import { EnhancedCaasProfile } from '../../../../../common/models-carriers'
import { filterAvailableCaasProfiles } from '../../../../../common/proxy/carrier-services-store'
import { getCarrierServicesOptions } from './carrier-services-options'

interface Props {
  calendarName?: string
  carrierServicesToCalendarNames: { [key: string]: string }
  caasProfiles: EnhancedCaasProfile[]
  data: CarrierSelection
  carrierValidation: ValidationResult
  onChange(data: CarrierSelection): void
}

export default function CarrierSelector(props: Props) {
  const { t } = useTranslation()

  const filteredCaasProfiles = filterAvailableCaasProfiles(props.caasProfiles)

  const carrierOptions: Option[] = filteredCaasProfiles
    .map(carrier => ({
      value: carrier.key,
      label: carrier.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const onCarrierChange = (changeData: Option) => {
    if (!changeData) {
      props.onChange({})

      return
    }

    const carrierServicesOptions = changeData
      ? getCarrierServicesOptions(
          t,
          props.data,
          filteredCaasProfiles,
          props.carrierServicesToCalendarNames,
          changeData.value,
          props.calendarName
        )
      : []
    const autoSelectCarrierServices =
      carrierServicesOptions.length === 1
        ? carrierServicesOptions
            .filter(o => !o.isDisabled)
            .map(cso => cso.value as string)
        : []

    props.onChange({
      carrier: changeData ? (changeData.value as string) : undefined,
      carrierServices: autoSelectCarrierServices,
    })
  }

  return (
    <ValidationContainer validationResult={props.carrierValidation}>
      <Select
        label={t('carrierAccounts.addCarrierFlow.selectCarrierLabel')}
        value={carrierOptions.filter(o => o.value === props.data.carrier)}
        options={carrierOptions}
        isClearable={true}
        required={true}
        onChange={onCarrierChange}
        menuPortalTarget={document.getElementById(reactSelectPortalId)}
      />
    </ValidationContainer>
  )
}
