import * as client from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import * as uuid from 'uuid'
import * as models from '../../../common/models'
import { getAllLocations } from '../../../common/proxy/locations-store'
import * as qcmStore from '../../../common/proxy/qcm-store'
import { LinkData } from '../view-location-page/QcmLink'
import auth from './../../../common/auth'
import MigrationStatusTable, {
  FlagStatus,
} from './location-migration-status-table'

export enum MIGRATION_FLAGS {
  useWorkingDayCalendarsInQuoter = 'use-working-day-calendars-in-quoter',
  useTransitCalendarsInQuoter = 'use-transit-calendars-in-quoter',
  csToPickupMappingQuoter = 'cs-to-pickup-mapping-quoter',
  useCountryCalendarsInQuoter = 'use-country-calendars-in-quoter',
}

const getFeatureFlagsMapping = async () => {
  const token = auth.getAccessToken()
  const promises = Object.values(MIGRATION_FLAGS).map(async flag => {
    const ff = await client.featureFlags.getLocationsByFlag(
      token,
      uuid.v4(),
      flag
    )

    return {
      [flag]: ff.reduce((accum: string[], value) => {
        if (value.value) {
          accum.push(value.locationId)
        }

        return accum
      }, []),
    }
  })

  return Promise.all(promises)
}

const getQcmLinks = async (
  locations: models.Location[]
): Promise<Record<string, LinkData[]>> => {
  return locations.reduce(
    async (acc: Promise<Record<string, LinkData[]>>, location) => {
      const resolvedAcc = await acc
      resolvedAcc[location.id] = await Promise.all(
        location.fulfillmentLocations.map(async fl => ({
          id: fl.id,
          link: await qcmStore.getQcmLink(fl.id),
          flName: fl.name,
          fulfillerName: fl.fulfiller.name,
        }))
      )

      return resolvedAcc
    },
    Promise.resolve({})
  )
}

export default function LocationMigrationStatusContainer() {
  const [loading, setLoading] = React.useState(false)
  const [locations, setLocations] = React.useState<models.Location[]>([])
  const [qcmLinks, setQcmLinks] = React.useState<Record<string, LinkData[]>>({})
  const [flags, setFlags] = React.useState<
    { [key in MIGRATION_FLAGS]?: string[] }
  >(
    Object.values(MIGRATION_FLAGS).reduce((accum, value) => {
      accum[value] = []

      return accum
    }, {})
  )

  const reloadLocations = async () => {
    setLoading(true)
    const [allLocations, allFeatureFlags] = await Promise.all([
      getAllLocations(),
      getFeatureFlagsMapping(),
    ])
    const values = Array.from(allLocations.values())
    setLocations(values)
    setFlags(Object.assign({}, ...allFeatureFlags))
    setLoading(false)

    return values
  }

  const toggleFeatureFlag = async (
    locationId: string,
    locationName: string,
    featureFlagId: MIGRATION_FLAGS,
    value: FlagStatus
  ): Promise<void> => {
    const confirmText = `You sure you want to turn ${
      value === 'on' ? 'off' : 'on'
    } ${featureFlagId} for ${locationName}?`
    const confirmed = window.confirm(confirmText)

    if (confirmed && value === 'off') {
      await client.featureFlags.putFeatureFlag(
        auth.getAccessToken(),
        uuid.v4(),
        featureFlagId,
        locationId,
        true
      )
      await reloadLocations()

      return
    }

    if (confirmed && value === 'on') {
      await client.featureFlags.deleteFeatureFlag(
        auth.getAccessToken(),
        uuid.v4(),
        featureFlagId,
        locationId
      )
      await reloadLocations()

      return
    }
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const allLocations = await reloadLocations()
      const allQcmLinks = await getQcmLinks(allLocations)
      setQcmLinks(allQcmLinks)
    }
    fetchData()
  }, [])

  return (
    <Wrapper>
      <MigrationStatusTable
        flags={flags}
        qcmLinks={qcmLinks}
        locations={locations}
        toggleFeatureFlag={toggleFeatureFlag}
        loading={loading}
      />
    </Wrapper>
  )
}

function Wrapper(props: { children: React.ReactNode }) {
  return <main className="App-content container">{props.children}</main>
}
