import { bearerToken } from '../auth'
import {
  CarrierServiceOtc,
  CarrierServiceOtcResponse,
  LocationOtcResponse,
  LocationOtsResponse,
  NetworkOtcResponse,
  NetworkOtsResponse,
  OTC_PER_CARRIER_SERVICE_NETWORK,
  OTC_PER_LOCATION,
  OTC_PER_NETWORK,
  OTS_PER_LOCATION,
  OTS_PER_NETWORK,
  Reports,
  SHIPMENTS_PER_CARRIER_SERVICE,
  SHIPMENTS_PER_FULFILLMENT_LOCATION,
  ShipmentsPerCarrierService,
  ShipmentsPerCarrierServiceResponse,
  ShipmentsPerFulfillmentLocationResponse,
  ShipmentsQuery,
} from './cimba-models'
import {
  CosmeticFilter,
  generateLink,
  LookerConfig,
  runLookerQuery,
} from './cimba-proxy'

async function runReport<T>(
  report: Reports,
  query: ShipmentsQuery = {},
  viewName?: string
): Promise<T[]> {
  const filters: CosmeticFilter[] = []

  if (query.networkId) {
    filters.push(filterByNetworkId(query.networkId, viewName))
  }

  const config = getLookerConfig(report, {
    ...query,
    filters,
  })

  const response = await runLookerQuery<T>(bearerToken(), config)

  return response.lookOutput
}
export async function getShipmentsPerCarrierService(
  query: ShipmentsQuery = {}
): Promise<ShipmentsPerCarrierService[]> {
  const response = await runReport<ShipmentsPerCarrierServiceResponse>(
    SHIPMENTS_PER_CARRIER_SERVICE,
    query
  )

  return response.map(row => ({
    networkId: row['logistics_network.networkid'],
    carrierServiceName: row.carrier_service_name,
    carrierServiceKey: row['logistics_recs.carrier_service_key'],
    shipments: row.shipments,
  }))
}

export async function getCarrierServiceOtcMap(
  query: ShipmentsQuery = {}
): Promise<Map<string, CarrierServiceOtc>> {
  const response = await runReport<CarrierServiceOtcResponse>(
    OTC_PER_CARRIER_SERVICE_NETWORK,
    query,
    'tis_otc'
  )

  return response.reduce((acc, current) => {
    acc.set(current['tis_otc.carrierservicekey'], {
      otc: toPercent(current.otc),
      carrierKey: current['tis_otc.carriername'],
    })

    return acc
  }, new Map<string, CarrierServiceOtc>())
}

export async function getLocationShipmentsMap(
  query: ShipmentsQuery = {}
): Promise<Map<string, number>> {
  const response = await runReport<ShipmentsPerFulfillmentLocationResponse>(
    SHIPMENTS_PER_FULFILLMENT_LOCATION,
    query
  )

  return response.reduce((acc, current) => {
    if (!current['logistics_network_tier1.logisticslocationid']) {
      return acc
    }

    acc.set(
      current['logistics_network_tier1.logisticslocationid'],
      current.shipments
    )

    return acc
  }, new Map<string, number>())
}

export async function getLocationOtcMap(
  query: ShipmentsQuery = {}
): Promise<Map<string, number>> {
  const response = await runReport<LocationOtcResponse>(
    OTC_PER_LOCATION,
    query,
    'tis_otc'
  )

  return response.reduce((acc, current) => {
    acc.set(current['tis_otc.logisticslocationid'], current.otc)

    return acc
  }, new Map<string, number>())
}

export async function getLocationOtsMap(
  query: ShipmentsQuery = {}
): Promise<Map<string, number>> {
  const response = await runReport<LocationOtsResponse>(
    OTS_PER_LOCATION,
    query,
    'ots'
  )

  return response.reduce((acc, current) => {
    acc.set(current['ots.logistics_location_id'], current.ots)

    return acc
  }, new Map<string, number>())
}

export async function getOnTimetoShipPercentage(query: ShipmentsQuery = {}) {
  const response = await runReport<NetworkOtsResponse>(
    OTS_PER_NETWORK,
    query,
    'ots'
  )

  if (response.length === 0) {
    return 'NA'
  }

  return toPercent(response[0].ots)
}

export async function getOnTimetoCustomerPercentage(
  query: ShipmentsQuery = {}
) {
  const response = await runReport<NetworkOtcResponse>(
    OTC_PER_NETWORK,
    query,
    'tis_otc'
  )

  if (response.length === 0) {
    return 'NA'
  }

  return toPercent(response[0].otc)
}

function getLookerConfig(
  configurationId: string,
  query: { limit?: number; filters?: CosmeticFilter[] }
): LookerConfig {
  return {
    configurationId,
    rowLimit: query.limit || 500,
    cosmeticFilters: query.filters,
  }
}

function filterByNetworkId(
  networkId: string,
  viewName = 'logistics_network_tier1'
): CosmeticFilter {
  return {
    viewName,
    dimName: 'networkid',
    filterValue: networkId,
  }
}

export function toPercent(n: number) {
  return Math.round(n * 100).toFixed(0) + '%'
}

export async function generateEmbedUrl(
  configurationId: string,
  locationId: string
) {
  const result = await generateLink(bearerToken(), configurationId, [
    { filter: 'Logistic Location ID', filterValue: locationId },
  ])

  return result.signedEmbedUrl
}
