import * as coam from '@cimpress-technology/coam-sapidus'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as uuid from 'uuid'
import { logError } from '../../common/logger'
import { SnackbarController } from '../../common/components/SnackbarController'
import useQuerystring from '../../common/hooks/useQuerystring'
import { EnhancedCaasProfile } from '../../common/models-carriers'
import {
  checkPermission,
  updateWorkingDaysCalendar,
} from '../../common/proxy/calendars-store'
import { filterAvailableCaasProfiles } from '../../common/proxy/carrier-services-store'
import { CalendarType, CreateTransitCalendar } from '../models'
import {
  addProfile,
  EditTransitCalendar,
  loadCalendarsData,
  TransitCalendar,
  TransitCalendarWithData,
} from './transit-calendar-commons'
import TransitCalendarView from './TransitCalendarView'

interface Props {
  canCreate: boolean
  children?: React.ReactNode
  transitCalendars: TransitCalendar[]
  forceDisabled?: boolean

  getCaasProfiles: () => Promise<EnhancedCaasProfile[]>
  changeCalendarView(type: CalendarType): void
  updateOwner(
    calendarId: string,
    carrierServices: string[],
    correlationId: string
  ): Promise<void>

  createTransitCalendar(
    data: CreateTransitCalendar,
    correlationId: string
  ): Promise<string | undefined>
}

export default function TransitCalendarContainer(props: Props) {
  const [loading, setLoading] = React.useState(true)
  const [querystring, setQuerystring] = useQuerystring()
  const { t } = useTranslation()
  const [saving, setSaving] = React.useState(false)
  const [editable, setEditable] = React.useState(false)
  const [transitCalendars, setTransitCalendars] = React.useState<
    TransitCalendarWithData[]
  >([])
  const [caasProfiles, setCaasProfiles] = React.useState<EnhancedCaasProfile[]>(
    []
  )

  React.useEffect(() => {
    const fetchData = async () => {
      if (querystring.calendar) {
        const isEditable = await checkPermission(
          querystring.calendar,
          coam.models.CalendarPermissions.Update
        )

        setEditable(props.forceDisabled ? false : isEditable)
      }
    }

    fetchData()
  }, [props.forceDisabled, querystring.calendar])

  React.useEffect(() => {
    const fetchData = async () => {
      const [fetchedCalendars, fetchedCassProfiles] = await Promise.all([
        loadCalendarsData(props.transitCalendars),
        props.getCaasProfiles(),
      ])

      fetchedCalendars.sort((a, b) =>
        a.workingDaysCalendar.name!.localeCompare(b.workingDaysCalendar.name!)
      )

      const firstCalendar =
        fetchedCalendars.length > 0 ? fetchedCalendars[0].calendarId : undefined
      const nextCurrentCalendar = fetchedCalendars.some(
        c => c.calendarId === querystring.calendar
      )
        ? querystring.calendar
        : firstCalendar
      if (querystring.calendar !== nextCurrentCalendar) {
        setQuerystring({ calendar: nextCurrentCalendar })
      }
      const carrierTypesReverseMap = getCarrierTypeMap(fetchedCassProfiles)

      setTransitCalendars(
        fetchedCalendars.map(calendar =>
          addProfile(carrierTypesReverseMap, calendar)
        )
      )
      setCaasProfiles(fetchedCassProfiles)
      setLoading(false)
    }

    fetchData()
  }, [props, props.transitCalendars, querystring.calendar, setQuerystring])

  const update = async (data: EditTransitCalendar) => {
    if (!data.calendar) {
      return
    }
    setSaving(true)
    try {
      const correlationId = uuid.v4()
      if (data.calendar.workingDaysCalendar!.name !== data.calendarName) {
        await updateWorkingDaysCalendar(
          data.calendar.calendarId,
          data.calendar.workingDaysCalendar!.etag!,
          [
            {
              op: 'replace',
              path: '/name',
              value: data.calendarName,
            },
          ],
          correlationId
        )
      }

      await props.updateOwner(
        data.calendar.calendarId,
        data.carrierSelection.carrierServices!,
        correlationId
      )

      SnackbarController.show(
        t('calendars.transit.transitCalendarUpdated', {
          name: data.calendar.workingDaysCalendar.name,
        }),
        'success'
      )
    } catch (error) {
      SnackbarController.show(
        t('calendars.transit.updatingTransitCalendarFailed', {
          name: data.calendar.workingDaysCalendar.name,
        }),
        'danger'
      )
      logError(
        `Error when updating calendar: ${data.calendar.calendarId}`,
        error
      )
    }
    setSaving(false)
  }

  const create = async (data: CreateTransitCalendar) => {
    setSaving(true)
    try {
      const correlationId = uuid.v4()
      const calendarId = await props.createTransitCalendar(data, correlationId)

      if (!calendarId) {
        return
      }

      await props.updateOwner(
        calendarId,
        data.carrierSelection.carrierServices!,
        correlationId
      )

      SnackbarController.show(
        t('calendars.transit.transitCalendarCreated', {
          name: data.calendarName,
        }),
        'success'
      )
      setQuerystring({ calendar: calendarId })
    } catch (error) {
      SnackbarController.show(
        t('calendars.transit.creatingTransitCalendarFailed', {
          name: data.calendarName,
        }),
        'danger'
      )
      logError(`Error when creating calendar: ${data}`, error)
    }
    setSaving(false)
  }

  const currentCalendar = transitCalendars.find(
    tc => tc.calendarId === querystring.calendar
  )

  return (
    <TransitCalendarView
      saving={saving}
      canCreate={props.canCreate}
      editable={editable}
      selectedCalendar={currentCalendar}
      transitCalendars={transitCalendars}
      loading={loading}
      changeCalendarView={props.changeCalendarView}
      changeSchedule={changeCurrentCalendar}
      caasProfiles={caasProfiles}
      onUpdate={update}
      onCreate={create}
    >
      {props.children}
    </TransitCalendarView>
  )

  function changeCurrentCalendar(selected: string) {
    setQuerystring({ calendar: selected })
  }
}

function getCarrierTypeMap(profiles: EnhancedCaasProfile[]) {
  const carrierTypesReverseMap = new Map<string, Set<EnhancedCaasProfile>>()
  for (const profile of filterAvailableCaasProfiles(profiles)) {
    for (const caas of profile.carrierServices) {
      const profilesList =
        carrierTypesReverseMap.get(caas.key) || new Set<EnhancedCaasProfile>()
      profilesList.add(profile)

      carrierTypesReverseMap.set(caas.key, profilesList)
    }
  }

  return carrierTypesReverseMap
}
