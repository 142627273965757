import { calendars } from '@cimpress-technology/logistics-configuration-client'
import { Location } from '../../../../../common/models'

export function getInitialPickupCalendarState(
  location: Location
): calendars.models.CreatePickupCalendar {
  return {
    name: '',
    weeklySchedules: [],
    overwrites: {},
    workingDaysCalendar: { id: location.workingDaysCalendar.id },
    timezone: location.localeSettings.timezone,
    owner: { logisticsLocationId: location.id },
  }
}
