import * as coam from '@cimpress-technology/coam-sapidus'
import * as React from 'react'
import { useParams } from 'react-router'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import { v4 } from 'uuid'
import * as jsonPatch from 'fast-json-patch'
import Preloader from '../common/components/Preloader'
import FourOhFourPage from '../common/FourOhFourPage'
import {
  Location,
  ShippingConsoleStatus,
  ReportsCustomData,
  Report,
} from '../common/models'
import {
  getLocation,
  updateLocation as updateLogisticsLocation,
} from '../common/proxy/locations-store'
import { checkPermission } from '../common/proxy/networks-store'
import {
  checkShippingConsole,
  enableShippingConsole as enableShippingConsoleForLocation,
} from '../common/proxy/backend-store'
import { bearerToken } from '../common/auth'
import { useIsMounted } from '../common/hooks/useIsMounted'
import { clone } from '../common/helpers/clone'
import { LocationContext, Provider } from './LocationContext'
import LocationPage from './LocationPage'

export default function LocationPageContainer() {
  const { locationId } = useParams<{ locationId: string }>()
  const [currentLocation, setCurrentLocation] = React.useState<Location>()
  const [loading, setLoading] = React.useState(true)
  const [isNetworkAdmin, setIsNetworkAdmin] = React.useState(false)
  const [status, setStatus] = React.useState<ShippingConsoleStatus | undefined>(
    undefined
  )
  const [reports, setReports] = React.useState<Report[]>([])
  const isMounted = useIsMounted()

  React.useEffect(() => {
    const fetchLocation = async () => {
      setLoading(true)
      const [logisticsLocation, additionalReports] = await Promise.all([
        getLocation(locationId),
        locations.getCustomData<ReportsCustomData>(
          bearerToken(),
          v4(),
          locationId,
          'reports'
        ),
      ])
      const network =
        logisticsLocation &&
        logisticsLocation._links &&
        logisticsLocation._links.network
      const isEditable = network
        ? await checkPermission(
            network.id,
            coam.models.LogisticsNetworkPermissions.Update
          )
        : false

      setIsNetworkAdmin(isEditable)
      setCurrentLocation(logisticsLocation)
      setReports(additionalReports?.data.reports ?? [])
      setLoading(false)
    }

    fetchLocation()
  }, [locationId])

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await checkShippingConsole(locationId)
      if (isMounted.current) {
        setStatus(result)
      }
    }

    fetchData()
  }, [isMounted, locationId])

  const updateLocation = async (
    change:
      | locations.models.Location
      | ((location: locations.models.Location) => void)
  ): Promise<void> => {
    const correlationId = v4()
    let locationCopy
    if (typeof change === 'function') {
      locationCopy = clone(currentLocation)
      change(locationCopy!)
    } else {
      locationCopy = change
    }

    const patches = jsonPatch
      .compare(currentLocation!, locationCopy!)
      .filter(patch => !patch.path.includes('href'))

    if (patches.length > 0) {
      await updateLogisticsLocation(
        locationId,
        currentLocation!.etag,
        patches,
        correlationId
      )
    }

    await reloadLocation()
  }

  const reloadLocation = async () => {
    const location = await getLocation(locationId)
    setCurrentLocation(location)
  }

  const enableShippingConsole = async () => {
    const response = await enableShippingConsoleForLocation(locationId)
    setStatus(response)
  }

  if (loading) {
    return <Preloader />
  }

  if (!currentLocation) {
    return <FourOhFourPage />
  }

  const context: LocationContext = {
    logisticsLocation: currentLocation,
    updateLocation,
    loading,
    isNetworkAdmin,
    shippingConsole: {
      status,
      enableShippingConsole,
    },
    reports,
  }

  return (
    <Provider value={context}>
      <LocationPage />
    </Provider>
  )
}
