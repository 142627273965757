import * as coam from '@cimpress-technology/coam-sapidus'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { checkPermission } from '../../common/proxy/calendars-store'
import { useLogisticsLocation } from '../../locations/LocationContext'
import { CalendarType } from '../models'
import { AdvancedUserContainer } from '../../common/components/AdvancedUserContainer'
import { PlantCalendarsLegend } from './legend/CalendarLegend'
import { SelectCalendar } from './SelectCalendar'
import YearlyCalendar from './YearlyCalendar'

interface Props {
  changeCalendarView(type: CalendarType): void
}

export default function WorkingDaysCalendar(props: Props) {
  const { t } = useTranslation()
  const [editable, setEditable] = React.useState(false)
  const { logisticsLocation } = useLogisticsLocation()

  React.useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      const isEditable = await checkPermission(
        logisticsLocation.workingDaysCalendar.id,
        coam.models.CalendarPermissions.Update
      )

      if (mounted) {
        setEditable(isEditable)
      }
    }

    fetchData()

    return () => {
      mounted = false
    }
  }, [logisticsLocation.workingDaysCalendar])

  return (
    <>
      <div
        className="col-xs-12 col-sm-4 col-md-4 col-lg-3 calendar-legend container"
        style={{ paddingBottom: '16px' }}
      >
        <div className="card" style={{ marginBottom: '16px' }}>
          <div className="card-block plant-calendar-legend">
            <SelectCalendar />
            <PlantCalendarsLegend />
          </div>
        </div>
        <AdvancedUserContainer enableTooltip={true}>
          <div className="card card-minimal">
            <div className="card-header">
              <h5 className="card-title">Advanced</h5>
            </div>
            <div className="card-block">
              <Link
                to={`/audits/calendar/${logisticsLocation.workingDaysCalendar.id}`}
              >
                {t('common.history')}
              </Link>
            </div>
          </div>
        </AdvancedUserContainer>
      </div>
      <YearlyCalendar
        editable={editable}
        calendar={logisticsLocation.workingDaysCalendar.id}
        changeCalendarView={props.changeCalendarView}
      />
    </>
  )
}
