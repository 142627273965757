import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import Preloader from '../common/components/Preloader'
import Fragment from '../common/components/Fragment'
import { NetworkBreadCrumbItem } from '../common/components/RouterWithBreadcrumbs'
import * as models from '../common/models'

interface Props {
  location: models.Location
}

export default function PackingFragment(props: Props) {
  const { t } = useTranslation()

  return (
    <>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <NetworkBreadCrumbItem />
      <BreadcrumbsItem to={`/location/${props.location.id}`}>
        {props.location.name}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">{t('packing.packing')}</BreadcrumbsItem>

      <Fragment
        src="https://ui.packer-config.logistics.cimpress.io/Fragment.js"
        fallback={<Preloader />}
      />
    </>
  )
}
