import { Link } from 'react-router-dom'
import * as React from 'react'
import LinkIcon from './LinkIcon'

interface Props {
  icon: React.ComponentClass<IconProps>
  url: string
  text: string
}

export default function NavigationLink(props: Props) {
  return (
    <li key={props.text} className="list-group-item">
      <Link to={props.url}>
        <LinkIcon icon={props.icon} />
        {props.text}
      </Link>
    </li>
  )
}
