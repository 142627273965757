import * as coam from '@cimpress-technology/coam-sapidus'
import {
  calendars,
  locations,
} from '@cimpress-technology/logistics-configuration-client'
import { updateLogisticsNetwork } from '@cimpress-technology/logistics-configuration-client/js/client/locations.v0'
import * as jsonPatch from 'fast-json-patch'
import moment from 'moment-timezone'
import * as uuid from 'uuid'
import auth, { bearerToken } from '../auth'
import { clone } from '../helpers/clone'
import { logError } from '../logger'
import * as models from '../models'

export const getWorkingDaysPreview = async (
  calendarId: string,
  startDate: moment.Moment,
  endDate: moment.Moment
): Promise<models.CalendarDisplay | undefined> => {
  const correlationId = uuid.v4()
  try {
    const result = await calendars.getWorkingDaysCalendar(
      auth.getAccessToken(),
      correlationId,
      calendarId
    )

    if (!result) {
      return undefined
    }

    const { id, etag, _links, ...workingDaysCalendar } = result

    const [days, holidays] = await Promise.all([
      calendars.previewWorkingDays(
        auth.getAccessToken(),
        correlationId,
        workingDaysCalendar,
        startDate,
        endDate
      ),
      workingDaysCalendar.holidayCalendar
        ? calendars.getHolidays(
            auth.getAccessToken(),
            correlationId,
            workingDaysCalendar.holidayCalendar.href!,
            startDate,
            endDate
          )
        : {},
    ])

    return { workingDaysCalendar: result, days: days!, holidays: holidays! }
  } catch (e) {
    logError('Error when previewing working days', e)

    return undefined
  }
}

export const updateWorkingDaysCalendar = async (
  id: string,
  etag: string,
  patch: jsonPatch.Operation[],
  correlationId: string
): Promise<void> => {
  await calendars.updateWorkingDaysCalendar(
    auth.getAccessToken(),
    correlationId,
    id,
    etag,
    patch
  )
}

export const createWorkingDaysCalendar = async (
  calendar: calendars.models.CreateWorkingDaysCalendar,
  correlationId: string
): Promise<string> => {
  return calendars.createWorkingDaysCalendar(
    auth.getAccessToken(),
    correlationId,
    calendar
  )
}

export function getDefaultWorkingDaysCalendar(
  timezone: string,
  countryCode?: string,
  logisticsLocationId?: string
): calendars.models.CreateWorkingDaysCalendar {
  const c: calendars.models.CreateWorkingDaysCalendar = {
    weeklySchedules: [
      {
        schedule: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: false,
          sunday: false,
        },
        validFrom: moment().tz(timezone).format('YYYY-MM-DD'),
      },
    ],
    overwrites: {},
  }

  if (countryCode) {
    c.holidayCalendar = { countryCode }
  }
  if (logisticsLocationId) {
    c.owner = { logisticsLocationId }
  }

  return c
}

export async function getPickupCalendarsForLocation(
  locationId: string,
  startDateTime: moment.Moment,
  endDateTime: moment.Moment
): Promise<calendars.models.PickupCalendarWithIncludes[] | undefined> {
  const response = await locations.getAllPickupCalendarsForLocation(
    auth.getAccessToken(),
    uuid.v4(),
    locationId,
    { include: ['days', 'editable'], startDateTime, endDateTime }
  )

  return response ? response.items : undefined
}

export function createPickupCalendar(
  calendar: calendars.models.CreatePickupCalendar,
  correlationId: string
): Promise<string> {
  return calendars.createPickupCalendar(
    auth.getAccessToken(),
    correlationId,
    calendar
  )
}

export function deletePickupCalendar(
  calendarId: string,
  correlationId: string
): Promise<void> {
  return calendars.deletePickupCalendar(
    auth.getAccessToken(),
    correlationId,
    calendarId
  )
}

export function deleteWorkingDaysCalendar(
  calendarId: string,
  correlationId: string
): Promise<void> {
  return calendars.deleteWorkingDaysCalendar(
    auth.getAccessToken(),
    correlationId,
    calendarId
  )
}

export function updatePickupCalendar(
  id: string,
  etag: string,
  patch: jsonPatch.Operation[],
  correlationId?: string
): Promise<string> {
  return calendars.updatePickupCalendar(
    auth.getAccessToken(),
    correlationId || uuid.v4(),
    id,
    etag,
    patch
  )
}

export function checkPermission(
  calendarId: string,
  permission: coam.models.CalendarPermissions
) {
  return coam.auth.isAllowed(
    calendarId,
    coam.models.ResourceTypes.Calendar,
    auth.getProfile().sub,
    permission,
    auth.getAccessToken(),
    uuid.v4()
  )
}

export function addCalendarToMapping(
  mapping:
    | locations.models.PickupCalendarMapping
    | locations.models.TransitCalendarMapping,
  calendarId: string,
  carrierServices: string[]
) {
  for (const csKey of carrierServices) {
    if (!mapping[csKey]) {
      mapping[csKey] = { id: calendarId }
      continue
    }

    if (mapping[csKey].id === calendarId) {
      continue
    }

    throw new Error(`Cannot map Calendar ${calendarId} to ${csKey}`)
  }
}

export function filterCarrierServicesForCalendar(
  mapping:
    | locations.models.PickupCalendarMapping
    | locations.models.TransitCalendarMapping,
  calendarId: string,
  carrierServices: string[]
) {
  const currentCsKeys = new Set(carrierServices)

  for (const [csKey, refObject] of Object.entries(mapping)) {
    if (currentCsKeys.has(csKey)) {
      continue
    }

    if (refObject.id === calendarId) {
      Reflect.deleteProperty(mapping, csKey)
    }
  }
}

export async function updateCalendarForNetwork(
  network: locations.models.LogisticsNetwork,
  calendarId: string,
  carrierServices: string[],
  correlationId: string
): Promise<void> {
  const originalLocation = clone(network)

  filterCarrierServicesForCalendar(
    network.transitCalendars.mapping,
    calendarId,
    carrierServices
  )
  addCalendarToMapping(
    network.transitCalendars.mapping,
    calendarId,
    carrierServices
  )

  const patches: jsonPatch.Operation[] = jsonPatch
    .compare(originalLocation, network)
    .filter(patch => !patch.path.includes('href'))

  await updateLogisticsNetwork(
    bearerToken(),
    correlationId,
    network.id,
    network.etag,
    patches
  )

  return
}
