import * as reactComponents from '@cimpress/react-components'
import * as React from 'react'
import * as models from '../../common/models'
import { useLogisticsLocation } from '../../locations/LocationContext'
import auth from '../auth'
import { config } from '../config/config'

export interface FragmentProps {
  src: string
  fallback: JSX.Element
  error?: (error: Error) => JSX.Element
}

interface FragmentComponentProps {
  environment: string
  token: string
  location: models.Location
  reloadLocation: () => Promise<void>
}

export default function Fragment(props: FragmentProps) {
  const token = auth.getAccessToken()
  const { logisticsLocation, updateLocation } = useLogisticsLocation()

  const reloadLocation = async () => {
    await updateLocation(() => undefined)
  }

  const [
    Component,
    setComponent,
  ] = React.useState<null | React.LazyExoticComponent<
    React.ComponentType<FragmentComponentProps>
  >>(null)

  const { src, error } = props

  React.useEffect(() => {
    if (src && src.length) {
      const promise = () =>
        import(/* webpackIgnore: true */ src)
          .then(module => ({
            default: module.default({ React, reactComponents }),
          }))
          .catch(e => {
            return {
              default: error ? error(e) : () => <div />,
            }
          })

      setComponent(React.lazy(promise))
    }
  }, [src, error])

  if (!Component) {
    return null
  }

  return (
    <React.Suspense fallback={props.fallback}>
      <Component
        environment={config.logisticsEnvironment}
        token={token}
        location={logisticsLocation}
        reloadLocation={reloadLocation}
      />
    </React.Suspense>
  )
}
