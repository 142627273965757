import { locations } from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import * as models from '../../common/models'
import {
  getLocationOtcMap,
  getLocationOtsMap,
  getLocationShipmentsMap,
} from '../../common/proxy/cimba-store'
import { getMultipleLocations } from '../../common/proxy/locations-store'
import NetworkTools from '../NetworkTools'
import styles from '../ViewNetworkPage.module.css'
import AddLocationToNetworkModal from './AddLocationToNetworkModal'
import LocationsTable from './LocationsTable'
import { ShippingOverview } from './ShippingOverview'
import { TopCarriers } from './TopCarriers'

interface Props {
  network: models.Network

  reloadNetwork: () => void
}

export default function NetworkDetails(props: Props) {
  const { t } = useTranslation()
  const [shipmentsPerLocation, setShipmentsPerLocation] = React.useState(
    new Map<string, number>()
  )
  const [locationOtcMap, setLocationOtcMap] = React.useState(
    new Map<string, number>()
  )

  const [locationOtsMap, setLocationOtsMap] = React.useState(
    new Map<string, number>()
  )
  const [loading, setLoading] = React.useState(true)
  const [
    showAddLocationToNetworkModal,
    setAddLocationToNetworkModal,
  ] = React.useState(false)
  const [locationsWithoutNetworks, setLocations] = React.useState<
    locations.models.LocationWithLinks[]
  >([])

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const query = {
        networkId: props.network.apiNetwork.id,
      }

      const [shipments, otc, ots, allLocations] = await Promise.all([
        getLocationShipmentsMap(query),
        getLocationOtcMap(query),
        getLocationOtsMap(query),
        getMultipleLocations(props.network.apiNetwork.logisticsLocationIds),
      ])

      setShipmentsPerLocation(shipments)
      setLocationOtcMap(otc)
      setLocationOtsMap(ots)
      setLocations(allLocations)
      setLoading(false)
    }
    fetchData()
  }, [props.network])

  const totalShipments = [...shipmentsPerLocation.values()].reduce(
    (acc, curr) => {
      acc += curr

      return acc
    },
    0
  )

  const openAddLocationToNetworkModal = () => {
    setAddLocationToNetworkModal(true)
  }

  const closeAddLocationToNetworkModal = (reloadLocations: boolean) => {
    setAddLocationToNetworkModal(false)
    if (reloadLocations) {
      props.reloadNetwork()
    }
  }

  const addLocationToNetworkModal = showAddLocationToNetworkModal ? (
    <AddLocationToNetworkModal
      network={props.network}
      onClose={closeAddLocationToNetworkModal}
    />
  ) : undefined

  return (
    <>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <BreadcrumbsItem to="#">{props.network.apiNetwork.name}</BreadcrumbsItem>
      <div className="row">
        <div className="col-xs-9">
          <div>
            <h4>{props.network.apiNetwork.name}</h4>
          </div>
        </div>
        <div className="col-xs-3" />
      </div>
      <div className={`row ${styles.marginTop}`}>
        <div className="col-xs-12 col-md-4">
          <NetworkTools />
        </div>

        <div className="col-xs-12  col-md-8">
          <ShippingOverview
            network={props.network.apiNetwork}
            loading={loading}
            totalShipments={totalShipments}
          />
          <TopCarriers network={props.network.apiNetwork} loading={loading} />
          <div className={`card card-minimal ${styles.locationsCard}`}>
            <div className="card-header">
              <h5 className="card-title" style={{ display: 'inline-block' }}>
                {t('common.network')}
              </h5>
              <button
                className="btn-link pull-right"
                onClick={openAddLocationToNetworkModal}
              >
                <i className="fa fa-fw fa-plus" />
                {t('locations.addLocation')}
              </button>
            </div>
            <div className="card-block">
              <LocationsTable
                loading={loading}
                shipmentsPerLocation={shipmentsPerLocation}
                locationOtcMap={locationOtcMap}
                locationOtsMap={locationOtsMap}
                locations={locationsWithoutNetworks}
              />
            </div>
          </div>
        </div>
      </div>
      {addLocationToNetworkModal}
    </>
  )
}
