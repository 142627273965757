import axios from 'axios'
import { logError } from '../logger'

const CIMBA_URL = 'https://nala.cimpress.io'

export interface LookerResponse<T> {
  lookOutput: T[]
}

export interface CosmeticFilter {
  viewName: string
  dimName: string
  filterValue: any
}

export interface LookerConfig {
  configurationId: string
  rowLimit: number
  cosmeticFilters?: CosmeticFilter[]
}

export interface Filter {
  filter: string
  filterValue: string
}

export async function runLookerQuery<T>(
  bearerToken: string,
  config: LookerConfig
): Promise<LookerResponse<T>> {
  try {
    const response = await axios.post(`${CIMBA_URL}/v0/looker/run`, config, {
      headers: {
        authorization: bearerToken,
      },
    })

    return response.data
  } catch (error) {
    logError('Error when running a looker query', error)
    throw error
  }
}

interface GenerateLinkResponse {
  signedEmbedUrl: string
}

export async function generateLink(
  bearerToken: string,
  configurationId: string,
  filters?: Filter[]
) {
  const response = await axios.post<GenerateLinkResponse>(
    `${CIMBA_URL}/v0/embed/generateLink`,
    {
      configurationId,
      filters,
    },
    {
      headers: {
        authorization: bearerToken,
      },
    }
  )

  return response.data
}
