import { Table } from '@cimpress/react-components'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Column, Filter, RowInfo } from 'react-table'
import OpenInNewTab from '../../../common/components/OpenInNewTab'
import Preloader from '../../../common/components/Preloader'
import * as models from '../../../common/models'
import { LinkData } from '../view-location-page/QcmLink'
import { MIGRATION_FLAGS } from './LocationMigrationStatusContainer'

export type FlagStatus = 'on' | 'off'

interface Row {
  id: string
  name: string
  network: string
  wdMigrated: FlagStatus
  pickupsMigratedV2: FlagStatus
  transitMigrated: FlagStatus
  countryMigrated: FlagStatus
  qcmLinks: LinkData[] | undefined
}

interface Props {
  flags: { [key in MIGRATION_FLAGS]?: string[] }
  locations: models.Location[]
  qcmLinks: Record<string, LinkData[]>
  loading: boolean
  toggleFeatureFlag: (
    locationId: string,
    locationName: string,
    featureFlagId: MIGRATION_FLAGS,
    value: FlagStatus
  ) => Promise<void>
}

const labels: Array<{ key: FlagStatus; value: '✅' | '❌' }> = [
  { key: 'on', value: '✅' },
  { key: 'off', value: '❌' },
]

export default function MigrationStatusTable(props: Props) {
  const onOffFilterMethod = (filter: any, row: any) => {
    if (filter.value === 'all') {
      return true
    }

    return row[filter.id] === filter.value
  }

  const OnOffFilter = ({ filter, onChange }: any) => {
    const onValueChange = (e: any) => onChange(e.target.value)

    return (
      <select
        onChange={onValueChange}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
      >
        <option value="all">Show All</option>
        {labels.map(label => (
          <option value={label.key} key={label.key}>
            {label.value}
          </option>
        ))}
      </select>
    )
  }

  const qcmEnabledFilterMethod = (filter: any, row: any) => {
    if (filter.value === 'all') {
      return true
    }

    const qcmLinks = row.qcmLinks as LinkData[] | undefined

    if (!qcmLinks) {
      return true
    }

    if (filter.value === 'qcm-on') {
      return qcmLinks.some(linkData => linkData.link)
    }

    if (filter.value === 'qcm-off') {
      return qcmLinks.every(linkData => !linkData.link)
    }

    return true
  }

  const QcmEnabledFilter = ({ filter, onChange }: any) => {
    const onValueChange = (e: any) => onChange(e.target.value)

    return (
      <select
        onChange={onValueChange}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
      >
        <option value="all">Show All</option>
        <option value="qcm-on">QCM Enabled</option>
        <option value="qcm-off">No QCM</option>
      </select>
    )
  }

  const columns: Array<Column<Row>> = [
    {
      Header: 'Location',
      id: 'id',
      Cell: (row: RowInfo) => {
        return (
          <Link to={`/location/${row.original.id}`}>{row.original.id}</Link>
        )
      },
      accessor: 'id',
      sortable: false,
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: 'name',
    },
    {
      Header: 'Network',
      id: 'network',
      accessor: 'network',
    },
    {
      Header: 'WD Calendar Migrated',
      id: 'wdMigrated',
      Cell: (row: RowInfo) => {
        const onClick = () =>
          props.toggleFeatureFlag(
            row.original.id,
            row.original.name,
            MIGRATION_FLAGS.useWorkingDayCalendarsInQuoter,
            row.original.wdMigrated
          )

        return (
          <div style={{ cursor: 'pointer' }} onClick={onClick}>
            {row.original.wdMigrated === 'on' ? '✅' : '❌'}
          </div>
        )
      },
      filterMethod: onOffFilterMethod,
      Filter: OnOffFilter,
      accessor: 'wdMigrated',
      className: 'text-center',
    },
    {
      Header: 'Pickups Migrated V2',
      id: 'pickupsMigratedV2',
      Cell: (row: RowInfo) => {
        const onClick = () =>
          props.toggleFeatureFlag(
            row.original.id,
            row.original.name,
            MIGRATION_FLAGS.csToPickupMappingQuoter,
            row.original.pickupsMigratedV2
          )

        return (
          <div style={{ cursor: 'pointer' }} onClick={onClick}>
            {row.original.pickupsMigratedV2 === 'on' ? '✅' : '❌'}
          </div>
        )
      },
      filterMethod: onOffFilterMethod,
      Filter: OnOffFilter,
      accessor: 'pickupsMigratedV2',
      className: 'text-center',
    },
    {
      Header: 'Transit Calendars Migrated',
      id: 'transitMigrated',
      Cell: (row: RowInfo) => {
        const onClick = () =>
          props.toggleFeatureFlag(
            row.original.id,
            row.original.name,
            MIGRATION_FLAGS.useTransitCalendarsInQuoter,
            row.original.transitMigrated
          )

        return (
          <div style={{ cursor: 'pointer' }} onClick={onClick}>
            {row.original.transitMigrated === 'on' ? '✅' : '❌'}
          </div>
        )
      },
      filterMethod: onOffFilterMethod,
      Filter: OnOffFilter,
      accessor: 'transitMigrated',
      className: 'text-center',
    },
    {
      Header: 'Country Calendars Migrated',
      id: 'countryMigrated',
      Cell: (row: RowInfo) => {
        const onClick = () =>
          props.toggleFeatureFlag(
            row.original.id,
            row.original.name,
            MIGRATION_FLAGS.useCountryCalendarsInQuoter,
            row.original.countryMigrated
          )

        return (
          <div style={{ cursor: 'pointer' }} onClick={onClick}>
            {row.original.countryMigrated === 'on' ? '✅' : '❌'}
          </div>
        )
      },
      filterMethod: onOffFilterMethod,
      Filter: OnOffFilter,
      accessor: 'countryMigrated',
      className: 'text-center',
    },
    {
      Header: 'QCM Configurations',
      id: 'qcmLinks',
      Cell: (row: RowInfo) => {
        return row.original.qcmLinks ? (
          (row.original.qcmLinks as LinkData[]).map((linkData, i) => {
            if (linkData.link) {
              return (
                <div key={i}>
                  <OpenInNewTab href={linkData.link}>
                    {linkData.flName}
                  </OpenInNewTab>
                </div>
              )
            }

            return <span key={i}>{linkData.flName}</span>
          })
        ) : (
          <Preloader small={true} />
        )
      },
      accessor: 'qcmLinks',
      className: 'text-center',
      sortable: false,
      filterMethod: qcmEnabledFilterMethod,
      Filter: QcmEnabledFilter,
    },
  ]

  const data: Row[] = props.locations.map(l => {
    return {
      id: l.id,
      name: l.name,
      network:
        (l._links && l._links.network && l._links.network.name) || 'NO_NETWORK',
      wdMigrated: props.flags[
        MIGRATION_FLAGS.useWorkingDayCalendarsInQuoter
      ]!.includes(l.id)
        ? 'on'
        : 'off',
      pickupsMigratedV2: props.flags[
        MIGRATION_FLAGS.csToPickupMappingQuoter
      ]!.includes(l.id)
        ? 'on'
        : 'off',
      transitMigrated: props.flags[
        MIGRATION_FLAGS.useTransitCalendarsInQuoter
      ]!.includes(l.id)
        ? 'on'
        : 'off',
      countryMigrated: props.flags[
        MIGRATION_FLAGS.useCountryCalendarsInQuoter
      ]!.includes(l.id)
        ? 'on'
        : 'off',
      qcmLinks: props.qcmLinks[l.id],
    }
  })

  return (
    <Table
      pageSize={data.length ? data.length : undefined}
      columns={columns}
      minRows={10}
      data={data}
      filterable={true}
      defaultFilterMethod={filterCaseInsensitive}
      loading={props.loading}
      showPagination={false}
    />
  )
}

function filterCaseInsensitive(filter: Filter, row: any, column: any): boolean {
  const id = filter.pivotId || filter.id

  return row[id] !== undefined
    ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
    : true
}
