import * as client from '@cimpress-technology/logistics-configuration-client'
import { TransitCalendarMapping } from '@cimpress-technology/logistics-configuration-client/js/models/locations'
import * as uuid from 'uuid'
import { bearerToken } from '../../common/auth'
import { EnhancedCaasProfile } from '../../common/models-carriers'
import { EditCalendar } from '../models'

export interface EditTransitCalendar extends EditCalendar {
  calendar: TransitCalendarWithData
}

export interface TransitCalendar {
  calendarId: string
  carrierServices: string[]
}

export interface TransitCalendarWithData extends TransitCalendar {
  workingDaysCalendar: client.calendars.models.WorkingDaysCalendar
  profile?: EnhancedCaasProfile
  hasComplexSetup?: boolean
}

export function getTransitCalendars(
  mapping: TransitCalendarMapping
): TransitCalendar[] {
  const transitCalendarsMap = new Map<string, string[]>()
  for (const [csKey, refObject] of Object.entries(mapping)) {
    if (!transitCalendarsMap.has(refObject.id)) {
      transitCalendarsMap.set(refObject.id, [csKey])
    } else {
      const carrierServicesList = transitCalendarsMap.get(
        refObject.id
      ) as string[]
      carrierServicesList.push(csKey)
    }
  }

  return [...transitCalendarsMap].map(([calendarId, carrierServices]) => ({
    calendarId,
    carrierServices,
  }))
}

export function loadCalendarsData(
  transitCalendars: TransitCalendar[]
): Promise<TransitCalendarWithData[]> {
  const cid = uuid.v4()

  return Promise.all(
    transitCalendars.map(async ({ calendarId, carrierServices }) => {
      const wd = await client.calendars.getWorkingDaysCalendar(
        bearerToken(),
        cid,
        calendarId
      )

      return { calendarId, workingDaysCalendar: wd!, carrierServices }
    })
  )
}

export function addProfile(
  profilesReverseMap: Map<string, Set<EnhancedCaasProfile>>,
  calendarData: TransitCalendarWithData
): TransitCalendarWithData {
  const profileSets = calendarData.carrierServices
    .map(cs => profilesReverseMap.get(cs))
    .reduce((acc, curr) => {
      if (curr) {
        const currentProfile = [...curr.values()][0]
        acc?.add(currentProfile)
      }

      return acc
    }, new Set<EnhancedCaasProfile>()) as Set<EnhancedCaasProfile>

  const hasComplexSetup = profileSets.size > 1
  const profile = hasComplexSetup
    ? {
        key: 'generic',
        name: 'generic',
        carrierServices: [...profileSets]
          .map(s => s.carrierServices)
          .reduce((acc, curr) => acc.concat(curr)),
        labelTemplates: [],
      }
    : [...profileSets].pop()

  return { ...calendarData, profile, hasComplexSetup }
}
