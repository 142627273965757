import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SnackbarController } from '../../../common/components/SnackbarController'
import { useLogisticsLocation } from '../../LocationContext'
import ShippingConsoleView from './ShippingConsoleView'

export default function ShippingConsoleContainer() {
  const { t } = useTranslation()
  const { shippingConsole } = useLogisticsLocation()
  const [enabling, setEnabling] = React.useState(false)

  const onClick = async () => {
    try {
      setEnabling(true)
      await shippingConsole.enableShippingConsole()

      SnackbarController.show(
        t('locations.viewLocationPage.enableShippingConsoleSuccess'),
        'success'
      )
    } catch (error) {
      SnackbarController.show(
        t('locations.viewLocationPage.enableShippingConsoleFailed'),
        'danger',
        3000
      )
    }
    setEnabling(false)
  }

  return (
    <ShippingConsoleView
      status={shippingConsole.status}
      enabling={enabling}
      onClick={onClick}
    />
  )
}
