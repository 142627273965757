import { Button, Modal, Radio, RadioGroup } from '@cimpress/react-components'
import { TFunction } from 'i18next'
import moment from 'moment-timezone'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SnackbarController } from '../../../common/components/SnackbarController'
import { logError } from '../../../common/logger'
import { useLogisticsLocation } from '../../../locations/LocationContext'
import { ChangeType, PickupCalendar } from '../../models'
import { DuplicatedPickupError } from './pickup-calendar-updater'
import { PickupTimeEditor } from './PickupTimeEditor'
import { VenloOnlyWarning } from './VenloOnlyWarning'
import { TagEditor } from './weekly-calendar/TagEditor'
import useWeeklyCalendarContext from './WeeklyCalendarContext'

interface Props {
  calendar: PickupCalendar
  startTime: moment.Moment
  tags: string[]
  tagsEnabled: boolean
  onClose: () => void
}

export default function EditPickupModal(props: Props) {
  const { t } = useTranslation()
  const context = useWeeklyCalendarContext()
  const { logisticsLocation } = useLogisticsLocation()
  const [loading, setLoading] = React.useState(false)
  const [changeType, setChangeType] = React.useState<ChangeType>('current')
  const [timeString, setTimeString] = React.useState(
    props.startTime.format('HH:mm')
  )
  const [tags, setTags] = React.useState([...props.tags])

  const onEdit = async () => {
    setLoading(true)
    try {
      await context.editPickupEvent(
        props.startTime,
        props.calendar,
        changeType,
        props.tags,
        timeString,
        tags
      )

      SnackbarController.show(
        <span>
          {getPickupAddedSnackbarMessage(t, changeType, {
            date: props.startTime,
            calendarName: props.calendar.name,
            timeString,
          })}
        </span>,
        'success'
      )
      context.setModal()
    } catch (e) {
      if (e instanceof DuplicatedPickupError) {
        SnackbarController.show(
          <span>
            {t('calendars.weekly.editPickupModal.samePickupExists', {
              date: e.conflictTime,
            })}
          </span>,
          'danger'
        )
      } else {
        SnackbarController.show(
          <span>
            {t('calendars.weekly.editPickupModal.pickupEditingFailed')}
          </span>,
          'danger'
        )
      }

      logError('Error removing pickup', e)
      setLoading(false)
    }
  }

  const footer = (
    <>
      <Button
        type="default"
        onClick={props.onClose}
        key="cancel-btn"
        disabled={loading}
      >
        {t('common.cancel')}
      </Button>
      <Button type="primary" onClick={onEdit} key="save-btn" disabled={loading}>
        {t('calendars.weekly.editPickupModal.changeTime')}
      </Button>
    </>
  )

  const setCurrent = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: ChangeType
  ) => setChangeType(value)

  const onTagsChanged = (newTags: string[]) => {
    setTags(newTags)
  }
  const setNewTime = (newTime: string) => {
    setTimeString(newTime)
  }
  const tagEditor = props.tagsEnabled ? (
    <TagEditor
      tags={tags}
      onTagsChanged={onTagsChanged}
      locationId={logisticsLocation.id}
    />
  ) : undefined

  return (
    <Modal
      show={true}
      onRequestHide={props.onClose}
      closeButton={!loading}
      closeOnOutsideClick={!loading}
      title={t('calendars.weekly.editPickupModal.title', {
        calendarName: props.calendar.name,
      })}
      footer={footer}
      style={{ width: '400px' }}
    >
      <div style={{ marginBottom: '12px' }}>
        <VenloOnlyWarning />
        <div style={{ marginBottom: '5px' }}>
          <b>{t('common.pickupTime')}</b>
        </div>
        <div>
          <PickupTimeEditor value={timeString} onChange={setNewTime} />
        </div>
      </div>
      <div>{tagEditor}</div>
      <div>
        <b>{t('calendars.weekly.editPickupModal.applyNewTime')}</b>
      </div>
      <RadioGroup
        onChange={setCurrent}
        defaultSelected={'current'}
        name="changeType"
      >
        <Radio
          label={t('calendars.weekly.editPickupModal.forSingleDate', {
            date: props.startTime,
          })}
          value="current"
        />

        <Radio
          label={t('calendars.weekly.editPickupModal.forWeekday', {
            date: props.startTime,
          })}
          value="futureDayOfWeek"
        />
        <Radio
          label={t('calendars.weekly.editPickupModal.forAllFuture', {
            date: props.startTime,
          })}
          value="allFutureDays"
        />
      </RadioGroup>
    </Modal>
  )
}

function getPickupAddedSnackbarMessage(
  t: TFunction,
  changeType: ChangeType,
  changeContext: {
    date: moment.Moment
    calendarName: string
    timeString: string
  }
): string {
  switch (changeType) {
    case 'current':
      return t(
        'calendars.weekly.editPickupModal.pickupChangedSingle',
        changeContext
      )

    case 'futureDayOfWeek':
      return t(
        'calendars.weekly.editPickupModal.pickupChangedWeekday',
        changeContext
      )

    case 'allFutureDays':
      return t(
        'calendars.weekly.editPickupModal.pickupChangedEveryday',
        changeContext
      )

    default:
      throw new Error(`Unhandled switch case '${changeType}'`)
  }
}
