import { TFunction } from 'i18next'
import { CarrierSelection } from '../../../../../carrier-configuration/components/add-carrier-flow/carrierAccountData'
import { Disabled, Option } from '../../../../../common/models'
import { EnhancedCaasProfile } from '../../../../../common/models-carriers'

export function getCarrierServicesOptions(
  t: TFunction,
  data: CarrierSelection,
  filteredCaasProfiles: EnhancedCaasProfile[],
  carrierServicesToCalendarNames: { [key: string]: string },
  carrierKey: string,
  currentCalendarName?: string
): Array<Option & Disabled> {
  const selectedCarrierServices = new Set(data.carrierServices)
  const carrierData = filteredCaasProfiles.find(
    carrier => carrier.key === carrierKey
  )
  if (!carrierData) {
    return []
  }

  return carrierData.carrierServices
    .map(service => {
      const calendarName = carrierServicesToCalendarNames[service.key]
      const label =
        calendarName &&
        calendarName !== currentCalendarName &&
        !selectedCarrierServices.has(service.key)
          ? t('calendars.weekly.addPickupCalendarModal.alreadyUsedIn', {
              serviceName: service.name,
              calendarName,
            })
          : service.name

      return {
        value: service.key,
        label,
        isDisabled: Boolean(
          calendarName && calendarName !== currentCalendarName
        ),
      }
    })
    .sort((a, b) => {
      if (a.isDisabled === b.isDisabled) {
        return a.label.localeCompare(b.label)
      }

      return Number(a.isDisabled) - Number(b.isDisabled)
    })
}
