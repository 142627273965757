import * as React from 'react'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import * as models from '../common/models'

export interface LocationContext {
  logisticsLocation: models.Location
  updateLocation: (
    change:
      | locations.models.Location
      | ((location: locations.models.Location) => void)
  ) => Promise<void>
  loading: boolean
  isNetworkAdmin: boolean

  shippingConsole: {
    status?: models.ShippingConsoleStatus
    enableShippingConsole: () => Promise<void>
  }
  reports: models.Report[]
}

export const Context = React.createContext<LocationContext>(
  {} as LocationContext
)

export const Provider = Context.Provider

export function useLogisticsLocation() {
  return React.useContext(Context)
}
