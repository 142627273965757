import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AdvancedUserContainer } from '../../../../common/components/AdvancedUserContainer'
import ContextMenu from '../../../../common/components/ContextMenu'
import ContextMenuItem from '../../../../common/components/ContextMenuItem'
import OverflowTooltip from '../../../../common/components/OverflowTooltip'
import Preloader from '../../../../common/components/Preloader'
import { PickupCalendarDisplay } from '../../../models'
import useWeeklyCalendarContext from '../WeeklyCalendarContext'
import { ColorCheckbox } from './ColorCheckbox'

interface PickupCalendarRowProps {
  loading: boolean
  pickupCalendarDisplay: PickupCalendarDisplay
  onEdit: (pickupCalendarId: string) => void
}

export function PickupCalendarLegendRow(props: PickupCalendarRowProps) {
  const { t } = useTranslation()

  const context = useWeeklyCalendarContext()
  const history = useHistory()

  const goToHistory = () => {
    history.push(`/audits/calendar/${props.pickupCalendarDisplay.calendar.id!}`)
  }

  const onEdit = () => {
    props.onEdit(props.pickupCalendarDisplay.calendar.id!)
  }

  const onChange = () => {
    context.onPickupCalendarToggle(props.pickupCalendarDisplay.calendar.id!)
  }

  const overflowTarget = (instance: HTMLElement) =>
    instance.firstChild!.firstChild!

  const editButton = props.loading ? (
    <Preloader small={true} />
  ) : (
    <ContextMenu>
      <ContextMenuItem onClick={onEdit} label={t('common.edit')} />
      <ContextMenuItem onClick={goToHistory} label={t('common.history')} />
    </ContextMenu>
  )

  return (
    <>
      <li className="with-button-on-right pickup-row">
        <OverflowTooltip
          tooltip={props.pickupCalendarDisplay.calendar.name}
          overflowTarget={overflowTarget}
        >
          <ColorCheckbox
            label={props.pickupCalendarDisplay.calendar.name}
            checked={props.pickupCalendarDisplay.active}
            color={props.pickupCalendarDisplay.color}
            onChange={onChange}
          />
        </OverflowTooltip>
        <AdvancedUserContainer enableTooltip={true}>
          {editButton}
        </AdvancedUserContainer>
      </li>
    </>
  )
}
