import { Select } from '@cimpress/react-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router'
import { Option } from '../../common/models'
import { useLogisticsLocation } from '../../locations/LocationContext'

interface Props {
  disabled?: boolean
}

export function SelectCalendar(props: Props) {
  const { t } = useTranslation()
  const history = useHistory()
  const match = useRouteMatch<{ calendarType: string }>()
  const { logisticsLocation } = useLogisticsLocation()

  if (!logisticsLocation) {
    return <></>
  }

  const options = [
    {
      label: t('common.calendars.locationAndPickupCalendars'),
      value: '/',
      targetUrl: `/location/${logisticsLocation.id}/calendars`,
    },
  ]

  if (logisticsLocation.showTransitCalendar) {
    options.push({
      label: t('common.calendars.transitCalendars'),
      value: 'transit',
      targetUrl: `/location/${logisticsLocation.id}/calendars/transit`,
    })
  }

  if (logisticsLocation.showCountryCalendar) {
    options.push({
      label: t('common.calendars.countryCalendars'),
      value: 'country',
      targetUrl: `/location/${logisticsLocation.id}/calendars/country`,
    })
  }

  if (options.length === 1) {
    return <></>
  }

  const onChange = (newValue: Option) => {
    const selected = options.find(option => option.value === newValue.value)
    if (selected) {
      history.push(selected.targetUrl)
    }
  }

  const value = options.find(
    o =>
      o.value === match.params.calendarType ||
      (o.value === '/' &&
        (match.params.calendarType === 'pickup' ||
          match.params.calendarType === 'working-days'))
  )

  return (
    <div
      style={{
        paddingBottom: '0px',
        borderBottomStyle: 'dashed',
        borderBottomWidth: '1px',
        borderBottomColor: 'var(--alloy)',
        marginBottom: '16px',
      }}
    >
      <Select
        label={t('common.calendars.calendars')}
        value={value}
        isClearable={false}
        onChange={onChange}
        options={options}
        isDisabled={props.disabled}
      />
    </div>
  )
}
