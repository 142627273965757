import * as React from 'react'
import { Label, TabCard, Alert } from '@cimpress/react-components'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import { Trans } from 'react-i18next'
import { useLogisticsLocation } from '../../LocationContext'
import { AdvancedUserContainer } from '../../../common/components/AdvancedUserContainer'
import useWideContainer from '../../../common/components/useContainer'
import { useNotifications } from '../../../common/hooks/useNotifications'
import OverviewLocationDetail from './OverviewLocationDetail'
import ConfigureCard from './ConfigureCard'
import ShippingConsoleContainer from './ShippingConsoleContainer'
import ReportViewer from './ReportViewer'
import OperationsCard from './OperationsCard'
import Notifications from './Notifications'
import styles from './Notifications.module.css'

export default function FulfillerOverviewPage() {
  useWideContainer()
  const { logisticsLocation } = useLogisticsLocation()
  const {
    loading,
    notifications,
    unreads,
    dismissUnreads,
    createNotification,
  } = useNotifications()
  const [selectedTab, setSelectedTab] = React.useState(0)

  const goToNotifications = () => setSelectedTab(1)

  const changeSelectedTab = (_: any, selectedKey: number) => {
    setSelectedTab(selectedKey)
  }

  const tabs: any = [
    {
      name: 'Dashboards',
      block: <ReportViewer />,
      href: '#',
    },
    {
      name: (
        <>
          <span style={{ marginRight: '2px' }}>Notifications</span>
          {unreads.length > 0 && selectedTab !== 1 ? (
            <Label text={unreads.length.toString()} type="danger" />
          ) : null}
        </>
      ),
      block: (
        <Notifications
          notifications={notifications}
          loading={loading}
          dismissUnreads={dismissUnreads}
          createNotification={createNotification}
        />
      ),
      href: '#',
    },
  ]

  return (
    <>
      <BreadcrumbsItem to="#">{logisticsLocation.name}</BreadcrumbsItem>
      <div className="row">
        <div className="col-xs-9">
          <div>
            <h4>{logisticsLocation.name}</h4>
          </div>
        </div>
        <div className="col-xs-3" />
      </div>
      <div className="row" style={{ marginTop: '20px', marginBottom: '32px' }}>
        <div className="col-xs-12 col-md-4 col-lg-3">
          <ConfigureCard />
          <OperationsCard />
          <OverviewLocationDetail />
          <AdvancedUserContainer
            enableTooltip={true}
            tooltipStyle={{ display: 'block' }}
          >
            <ShippingConsoleContainer />
          </AdvancedUserContainer>
        </div>
        <div
          className={`col-xs-12 col-md-8 col-lg-9 ${
            selectedTab === 1 ? styles.notificationCard : ''
          }`}
        >
          <NotificationAlert
            show={selectedTab !== 1}
            unreads={unreads}
            onClick={goToNotifications}
          />
          <TabCard
            tabs={tabs}
            selectedIndex={selectedTab}
            onSelect={changeSelectedTab}
          />
        </div>
      </div>
    </>
  )
}

function NotificationAlert(props: {
  show: boolean
  unreads: locations.models.Notification[]
  onClick: () => void
}) {
  const alerts = props.unreads.filter(n => n.type === 'alert').length

  if (props.show && alerts > 0) {
    return (
      <Alert
        type="info"
        message={
          <Trans i18nKey="locations.notifications.youHaveAlerts" count={alerts}>
            Just to let you know, you have {alerts} new important notifications.{' '}
            <button className={styles.linkButton} onClick={props.onClick}>
              Switch to notifications
            </button>
          </Trans>
        }
      />
    )
  }

  return null
}
