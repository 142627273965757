import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router'
import LearnMoreAboutCalendars from '../calendars/components/LearnMoreAboutCalendars'
import { CalendarType } from '../calendars/models'
import * as models from '../common/models'
import NetworkTransitCalendarContainer from './NetworkTransitCalendarContainer'

interface Props {
  network: models.Network
  reloadNetwork: () => void
}

export default function NetworkCalendarsPage(props: Props) {
  const { t } = useTranslation()
  const history = useHistory()
  const match = useRouteMatch()

  const changeCalendarView = (type: CalendarType) => {
    history.push(`/network/${props.network.apiNetwork.id}/calendars/${type}`)
  }

  return (
    <>
      <Switch>
        <Route path={`${match.path}/:calendarType`}>
          <div className="row flex-align-items-center">
            <div className="col-xs-8 col-lg-8">
              <h4>
                {t('calendars.transit.transitCalendar', {
                  locationName: props.network.apiNetwork.name,
                })}
              </h4>
            </div>
            <div className="col-xs-4 col-lg-4 text-right">
              <LearnMoreAboutCalendars />
            </div>
          </div>

          <NetworkTransitCalendarContainer
            network={props.network}
            changeCalendarView={changeCalendarView}
            reloadNetwork={props.reloadNetwork}
          />
        </Route>
      </Switch>
    </>
  )
}
