import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { getCountryName } from '../../i18n'
import * as models from '../models'
import { CountryFlag } from './CountryFlag'

export interface Props {
  address: models.LocationAddress
}

export default (props: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <h5>{t('locations.viewLocationPage.cityAndCountry')}</h5>
      <CountryFlag countryCode={props.address.countryCode} />{' '}
      {props.address.locality}
      {', '}
      {getCountryName(props.address.countryCode)}{' '}
    </>
  )
}
