import { Button, Modal, TextField } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CarrierSelection } from '../../carrier-configuration/components/add-carrier-flow/carrierAccountData'
import { ValidationContainer } from '../../common/components/ValidationContainer'
import {
  toBSStyle,
  validateField,
  validateList,
  ValidationStatus,
} from '../../common/helpers/validation'
import { EnhancedCaasProfile } from '../../common/models-carriers'
import {
  carrierServicesValidationField,
  carrierValidationField,
  CreateCalendarValidation,
  EditCalendar,
  nameValidationField,
} from '../models'
import CarrierSelector from './weekly/legend/pickup-calendar-editor/CarrierSelector'
import CarrierServicesSelector from './weekly/legend/pickup-calendar-editor/CarrierServicesSelector'

interface Props {
  title: string
  primaryButtonText: string
  primaryButtonSavingText: string
  saving: boolean
  carrierServicesToCalendarNames: { [key: string]: string }
  carrierAccountTypes: EnhancedCaasProfile[]

  children?: React.ReactNode
  onSave: (data: EditCalendar) => void
  onClose: () => void
}

export default function CalendarCreateModal(props: Props) {
  const { t } = useTranslation()
  const [calendarName, setCalendarName] = React.useState('')

  const [carrierSelection, setCarrierSelection] = React.useState<
    CarrierSelection
  >({ carrier: '', carrierServices: [] })

  const [validation, setValidation] = React.useState<CreateCalendarValidation>(
    getInitialCreateCalendarValidationStatus()
  )
  const onSave = async () => {
    const newValidationState = {
      name: validateField(nameValidationField, calendarName),
      carrier: validateField(carrierValidationField, carrierSelection.carrier),
      carrierServices: validateList(
        carrierServicesValidationField,
        carrierSelection.carrierServices
      ),
    }

    setValidation(newValidationState)

    const isCalendarValid = Object.values(newValidationState).every(
      field => field.status === ValidationStatus.Valid
    )

    if (isCalendarValid) {
      await props.onSave({ calendarName, carrierSelection })

      props.onClose()
    }
  }

  const footer = (
    <>
      <Button
        type="default"
        onClick={props.onClose}
        key="cancel-btn"
        disabled={props.saving}
      >
        {t('common.cancel')}
      </Button>
      <Button
        type="primary"
        onClick={onSave}
        key="save-btn"
        disabled={props.saving}
      >
        {props.saving ? props.primaryButtonSavingText : props.primaryButtonText}
      </Button>
    </>
  )

  const onNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCalendarName(e.currentTarget.value)
    setValidation({
      ...validation,
      name: validateField(nameValidationField, e.currentTarget.value),
    })
  }

  const onCarrierAccountDataChange = (
    newCarrierAccountData: CarrierSelection
  ) => {
    setCarrierSelection(newCarrierAccountData)
    const newValidationState = {
      ...validation,
      carrier: validateField(
        carrierValidationField,
        newCarrierAccountData.carrier
      ),
    }

    const carrierServicesAreInvalid =
      validation.carrierServices.status === ValidationStatus.Invalid

    if (carrierServicesAreInvalid) {
      newValidationState.carrierServices = validateList(
        carrierServicesValidationField,
        newCarrierAccountData.carrierServices
      )
    }
    setValidation(newValidationState)
  }

  return (
    <Modal
      show={true}
      onRequestHide={props.onClose}
      closeButton={true}
      closeOnOutsideClick={false}
      title={props.title}
      style={{ width: '526px' }}
      footer={footer}
    >
      <ValidationContainer validationResult={validation.name}>
        <TextField
          name="name"
          label={t('calendars.weekly.addPickupCalendarModal.scheduleName')}
          value={calendarName}
          onChange={onNameChange}
          autoFocus={true}
          required={true}
          bsStyle={toBSStyle(validation.name)}
          maxLength={100}
        />
      </ValidationContainer>
      {props.children}
      <div style={{ marginTop: '20px', marginBottom: '12px' }}>
        <h5>
          {t('calendars.weekly.addPickupCalendarModal.applyToCarrierServices')}
        </h5>
        <CarrierSelector
          carrierServicesToCalendarNames={props.carrierServicesToCalendarNames}
          caasProfiles={props.carrierAccountTypes}
          data={carrierSelection}
          onChange={onCarrierAccountDataChange}
          carrierValidation={validation.carrier}
        />
        <CarrierServicesSelector
          carrierServicesToCalendarNames={props.carrierServicesToCalendarNames}
          caasProfiles={props.carrierAccountTypes}
          data={carrierSelection}
          onChange={onCarrierAccountDataChange}
          carrierServicesValidation={validation.carrierServices}
        />
      </div>
    </Modal>
  )
}

export function getInitialCreateCalendarValidationStatus(): CreateCalendarValidation {
  return {
    name: {
      fieldName: 'name',
      status: ValidationStatus.NotValidated,
    },
    carrier: {
      fieldName: 'carrier',
      status: ValidationStatus.NotValidated,
    },
    carrierServices: {
      fieldName: 'carrierServices',
      status: ValidationStatus.NotValidated,
    },
  }
}
