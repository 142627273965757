import { Button, Modal, Select } from '@cimpress/react-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SnackbarController } from '../../../common/components/SnackbarController'
import * as models from '../../../common/models'
import { addLocationToNetwork } from '../../../common/proxy/locations-store'
import * as LocationContext from './LocationsContext'

interface Props {
  location?: models.Location
  onCloseModal: () => void
}

export function AddLocationToNetworkModal(props: Props) {
  const [selectedNetwork, setSelectedNetwork] = React.useState<string>()
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()
  const { reloadLocations, networks } = React.useContext(
    LocationContext.Context
  )

  const selectNetwork = (data: models.Option) => {
    setSelectedNetwork(data.value as string)
  }

  const onClickAddToNetwork = async () => {
    setLoading(true)
    try {
      await addLocationToNetwork(props.location!.id, selectedNetwork!)
      setLoading(false)
      await reloadLocations()
    } catch (error) {
      SnackbarController.show(
        t(
          'locations.selectLocationPage.addLocationToNetworkModal.errorAddingToNetwork'
        ),
        'danger'
      )
      setLoading(false)
    }
  }

  const footer = (
    <>
      <Button
        type="default"
        onClick={props.onCloseModal}
        key="cancel-btn"
        disabled={loading}
      >
        {t('common.cancel')}
      </Button>
      <Button
        type="primary"
        onClick={onClickAddToNetwork}
        key="save-btn"
        disabled={loading}
      >
        {loading
          ? t(
              'locations.selectLocationPage.addLocationToNetworkModal.addingToNetwork'
            )
          : t('locations.selectLocationPage.addToNetwork')}
      </Button>
    </>
  )

  const networkOptions = networks
    .filter(network => network.editable)
    .map(network => ({
      label: network.apiNetwork.name,
      value: network.apiNetwork.id,
    }))

  return (
    <Modal
      title={t('locations.selectLocationPage.addLocationToNetworkModal.title', {
        location: props.location ? props.location.name : '',
      })}
      show={props.location !== undefined}
      closeButton={true}
      onRequestHide={props.onCloseModal}
      footer={footer}
    >
      <h5>
        {t('locations.selectLocationPage.addLocationToNetworkModal.network')}
      </h5>
      <Select
        label={t(
          'locations.selectLocationPage.addLocationToNetworkModal.selectANetwork'
        )}
        isSearchable={true}
        isClearable={false}
        name={'addToNetwork'}
        value={networkOptions.filter(o => o.value === selectedNetwork)}
        options={networkOptions}
        onChange={selectNetwork}
        menuPortalTarget={document.getElementById(models.reactSelectPortalId)}
      />
    </Modal>
  )
}
