import { Button, Modal, Select } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Preloader from '../../common/components/Preloader'
import { SnackbarController } from '../../common/components/SnackbarController'
import * as models from '../../common/models'
import {
  addLocationToNetwork,
  getAllLocations,
} from '../../common/proxy/locations-store'

interface Props {
  network: models.Network

  onClose: (reloadLocations: boolean) => void
}

export default function AddLocationToNetworkModal(props: Props) {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(true)
  const [saving, setSaving] = React.useState(false)
  const [locations, setLocations] = React.useState<models.Location[]>([])
  const [selectedLocationId, setSelectedLocationId] = React.useState<string>()

  React.useEffect(() => {
    const fetchData = async () => {
      const allLocations = await getAllLocations()
      setLocations(
        Array.from(allLocations.values()).filter(
          location =>
            location._links && !location._links.network && location.editable
        )
      )

      setLoading(false)
    }
    fetchData()
  }, [props.network])

  const onSave = async () => {
    setSaving(true)
    try {
      await addLocationToNetwork(
        selectedLocationId!,
        props.network.apiNetwork.id
      )
      setLoading(false)
      SnackbarController.show(
        t('networks.addLocationToNetworkSuccess', {
          locationName: locations.find(
            location => location.id === selectedLocationId
          )!.name,
          networkName: props.network.apiNetwork.name,
        }),
        'success'
      )
      props.onClose(true)
    } catch (error) {
      SnackbarController.show(
        t(
          'locations.selectLocationPage.addLocationToNetworkModal.errorAddingToNetwork'
        ),
        'danger'
      )
      setLoading(false)
    }
  }

  const onClose = () => {
    props.onClose(false)
  }

  const footer = loading ? (
    <></>
  ) : (
    <>
      <Button
        type="default"
        onClick={onClose}
        key="cancel-btn"
        disabled={saving}
      >
        {t('common.cancel')}
      </Button>
      <Button
        type="primary"
        onClick={onSave}
        key="save-btn"
        disabled={saving || selectedLocationId === undefined}
      >
        {t(`${saving ? 'common.saving' : 'locations.addLocation'}`)}
      </Button>
    </>
  )

  const options = locations
    .map(location => ({ label: location.name, value: location.id }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const onChange = (data: models.Option) => {
    setSelectedLocationId(data.value)
  }

  const content = loading ? (
    <Preloader />
  ) : (
    <Select
      label={t('networks.selectALocation')}
      isSearchable={true}
      isClearable={false}
      name="location"
      value={options.filter(o => o.value === selectedLocationId)}
      options={options}
      onChange={onChange}
      menuPortalTarget={document.getElementById(models.reactSelectPortalId)}
    />
  )

  return (
    <Modal
      show={true}
      onRequestHide={onClose}
      closeButton={true}
      closeOnOutsideClick={false}
      title={t('networks.addLocationToNetwork', {
        networkName: props.network.apiNetwork.name,
      })}
      style={{ width: '526px' }}
      footer={footer}
    >
      {content}
    </Modal>
  )
}
