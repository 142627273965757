import * as coam from '@cimpress-technology/coam-sapidus'
import {
  calendars,
  locations,
} from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { getTransitCalendars } from '../calendars/components/transit-calendar-commons'
import TransitCalendarContainer from '../calendars/components/TransitCalendarContainer'
import { CalendarType, CreateTransitCalendar } from '../calendars/models'
import auth from '../common/auth'
import * as models from '../common/models'
import {
  getDefaultWorkingDaysCalendar,
  updateCalendarForNetwork,
} from '../common/proxy/calendars-store'
import { getCaasProfiles } from '../common/proxy/carrier-services-store'

interface Props {
  network: models.Network
  changeCalendarView(type: CalendarType): void
  reloadNetwork(): void
}

export default function NetworkTransitCalendarContainer(props: Props) {
  const updateNetwork = async (
    calendarId: string,
    carrierServices: string[],
    correlationId: string
  ) => {
    await updateCalendarForNetwork(
      props.network.apiNetwork,
      calendarId,
      carrierServices,
      correlationId
    )

    await reload()
  }

  const createTransitCalendar = async (
    data: CreateTransitCalendar,
    correlationId: string
  ) => {
    const wdCalendar = getDefaultWorkingDaysCalendar(
      props.network.apiNetwork.localeSettings.timezone
    )
    wdCalendar.weeklySchedules[0].schedule = data.weeklySchedule

    const transitCalendar = {
      ...wdCalendar,
      name: data.calendarName,
    }
    const transitCalendarId = await calendars.createWorkingDaysCalendar(
      auth.getAccessToken(),
      correlationId,
      transitCalendar
    )

    await locations.linkResourceToNetworkGroup(
      auth.getAccessToken(),
      correlationId,
      props.network.apiNetwork.id,
      transitCalendarId,
      coam.models.ResourceTypes.Calendar
    )

    return transitCalendarId
  }

  const getNetworkCaasProfiles = () => {
    return getCaasProfiles()
  }

  const reload = () => {
    props.reloadNetwork()

    return Promise.resolve()
  }

  return (
    <div
      className="row display-flex"
      style={{
        marginTop: '20px',
      }}
    >
      <TransitCalendarContainer
        transitCalendars={getTransitCalendars(
          props.network.apiNetwork.transitCalendars.mapping
        )}
        getCaasProfiles={getNetworkCaasProfiles}
        changeCalendarView={props.changeCalendarView}
        updateOwner={updateNetwork}
        createTransitCalendar={createTransitCalendar}
        canCreate={props.network.editable}
      />
    </div>
  )
}
