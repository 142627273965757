import { calendars } from '@cimpress-technology/logistics-configuration-client'
import * as jsonPatch from 'fast-json-patch'
import moment from 'moment-timezone'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as uuid from 'uuid'
import Preloader from '../../common/components/Preloader'
import { SnackbarController } from '../../common/components/SnackbarController'
import { clone } from '../../common/helpers/clone'
import {
  getWorkingDaysPreview,
  updateWorkingDaysCalendar,
} from '../../common/proxy/calendars-store'
import { createCountryCalendarForLocation } from '../../common/proxy/locations-store'
import { useLogisticsLocation } from '../../locations/LocationContext'
import {
  CalendarType,
  DayDate,
  DayInformation,
  YearInformation,
} from '../models'
import { generateYearInformation } from './calendar-information'
import { modifySchedules } from './modifySchedules'
import NavigationBar from './NavigationBar'
import { Context, YearlyCalendarContextProvider } from './YearlyCalendarContext'
import YearlyCalendarView from './YearlyCalendarView'

interface Props {
  children?: React.ReactNode
  editable: boolean
  calendar: string
  calenderView?: CalendarType
  changeCalendarView: (type: CalendarType) => void
}

export default function YearlyCalendar(props: Props) {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()
  const [year, setYear] = React.useState<number>(moment().year())
  const [yearData, setYearData] = React.useState<YearInformation>({})
  const [workingDaysCalendar, setWorkingDaysCalendar] = React.useState<
    calendars.models.WorkingDaysCalendarWithLinks
  >()
  const [loading, setLoading] = React.useState(true)
  const [showModalFor, setShowModalFor] = React.useState<DayDate>()
  const [savingException, setSavingException] = React.useState(false)

  React.useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      if (props.calendar) {
        setLoading(true)
        const workingDays = await fetchWorkingDays(props.calendar, year)

        if (mounted) {
          setYearData(workingDays!.yearData)
          setWorkingDaysCalendar(workingDays!.workingDaysCalendar)
          setLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      mounted = false
    }
  }, [year, props.calendar])

  if (loading) {
    return (
      <YearlyCalendarCard>
        <Preloader />
      </YearlyCalendarCard>
    )
  }

  if (!workingDaysCalendar) {
    return <div>{t('calendars.calendarsContainer.noCalendars')}</div>
  }

  if (!yearData) {
    return <div>{t('calendars.calendarsContainer.noCalendars')}</div>
  }

  const changeYear = async (yearToSet: number) => {
    if (year !== yearToSet) {
      setLoading(true)
      setYear(yearToSet)
    }
  }

  const openModal = (day: DayDate) => setShowModalFor(day)
  const closeModal = () => setShowModalFor(undefined)

  const changeToWeek = () => props.changeCalendarView('pickup')
  const changeToYear = () => props.changeCalendarView('working-days')

  const incrementYear = () => changeYear(year + 1)
  const decrementYear = () => changeYear(year - 1)
  const goToCurrentYear = () => changeYear(moment().year())

  const addException = (
    date: DayDate,
    modifySchedule: boolean,
    explanation: string
  ): void => {
    const dayInformation = yearData![date.month][date.day]

    if (modifySchedule) {
      // intentionally not awaited:
      updateSchedules(date, dayInformation)

      return
    }

    const desiredState = !dayInformation.isWorkingDay
    const newDayInformation = {
      isWorkingDay: desiredState,
      exceptionInformation: {
        reason: explanation,
      },
    }

    // intentionally not awaited:
    updateDayInformation(date.month, date.day, newDayInformation)
  }

  const removeException = (date: DayDate): void => {
    const dayInformation = yearData![date.month][date.day]
    const desiredState = !dayInformation.isWorkingDay
    const newDayInformation = {
      isWorkingDay: desiredState,
    }
    updateDayInformation(date.month, date.day, newDayInformation)
  }

  const updateDayInformation = async (
    month: number,
    day: number,
    dayInformation: DayInformation
  ) => {
    setSavingException(true)
    const formattedDate = moment([year, month - 1, day]).format('YYYY-MM-DD')

    let patchOperation: jsonPatch.Operation
    if (workingDaysCalendar.overwrites[formattedDate]) {
      patchOperation = {
        op: 'remove',
        path: `/overwrites/${formattedDate}`,
      }
    } else {
      patchOperation = {
        op: 'add',
        path: `/overwrites/${formattedDate}`,
        value: {
          isWorkingDay: dayInformation.isWorkingDay,
          description: dayInformation.exceptionInformation
            ? dayInformation.exceptionInformation.reason
            : undefined,
        },
      }
    }

    await patchAndReloadCalendar([patchOperation])
  }

  const patchAndReloadCalendar = async (
    patchOperations: jsonPatch.Operation[]
  ) => {
    try {
      const calendarId = workingDaysCalendar.id
      const [prefix, countryCode] = calendarId.split('-')
      if (prefix === 'default') {
        const patchedCalendar = jsonPatch.applyPatch(
          workingDaysCalendar,
          patchOperations
        ).newDocument
        const { id, etag, _links, ...cleanedCalendar } = patchedCalendar

        cleanedCalendar.owner = { logisticsLocationId: logisticsLocation.id }

        const newCountryCalendarId = await createCountryCalendarForLocation(
          logisticsLocation,
          cleanedCalendar,
          countryCode
        )
        const workingDays = await fetchWorkingDays(newCountryCalendarId, year)

        setYearData(workingDays!.yearData)
        setWorkingDaysCalendar(workingDays!.workingDaysCalendar)
      } else {
        await updateWorkingDaysCalendar(
          calendarId,
          workingDaysCalendar.etag!,
          patchOperations,
          uuid.v4()
        )

        const workingDays = await fetchWorkingDays(calendarId, year)

        setYearData(workingDays!.yearData)
        setWorkingDaysCalendar(workingDays!.workingDaysCalendar)
      }
    } catch (exception) {
      if (exception.response.status === 412) {
        SnackbarController.show(
          <span>{t('calendars.calendarsContainer.conflictMessage')}</span>,
          'danger'
        )
      }
      if (exception.response.status === 400) {
        const errorMessage =
          exception.response.data.errors.length === 1 ? (
            exception.response.data.errors[0].message
          ) : (
            <ul>
              {exception.response.data.errors.map((err: any) => (
                <li key={err.message}>{err.message}</li>
              ))}
            </ul>
          )
        SnackbarController.show(
          <span>
            {t('calendars.calendarsContainer.validationError', {
              errorMessage,
            })}
          </span>,
          'danger'
        )
      } else {
        throw exception
      }
    }

    setLoading(false)
    setSavingException(false)
    setShowModalFor(undefined)
  }

  const updateSchedules = async (
    date: DayDate,
    dayInformation: DayInformation
  ): Promise<void> => {
    const currentCalendar = workingDaysCalendar
    const updatedCalendar = {
      ...clone(currentCalendar),
      weeklySchedules: modifySchedules(
        currentCalendar.weeklySchedules!,
        date,
        !dayInformation.isWorkingDay
      ),
    }

    setWorkingDaysCalendar(updatedCalendar)
    const patchOperations = jsonPatch.compare(currentCalendar, updatedCalendar)
    await patchAndReloadCalendar(patchOperations)
  }

  const context: Context = {
    addException,
    removeException,
    savingException,
    showModalFor,
    openModal,
    closeModal,
    calendarView: props.calenderView || 'working-days',
    editable: props.editable,
    today: moment(),
  }

  return (
    <YearlyCalendarCard>
      <NavigationBar
        toggleWeek={changeToWeek}
        toggleYear={changeToYear}
        prevButton={decrementYear}
        nextButton={incrementYear}
        goToToday={goToCurrentYear}
      >
        {year}
      </NavigationBar>
      <YearlyCalendarContextProvider value={context}>
        {props.children}
        <YearlyCalendarView year={year} yearData={yearData} />
      </YearlyCalendarContextProvider>
    </YearlyCalendarCard>
  )
}

function YearlyCalendarCard(props: { children?: React.ReactNode }) {
  return (
    <div className="col-sm-8 col-md-8 col-lg-9 flex padding-bottom-full">
      <div className="card" style={{ width: '100%', height: '100%' }}>
        <div className="card-block year-calendar calendar-content flex-vertical">
          {props.children}
        </div>
      </div>
    </div>
  )
}

const fetchWorkingDays = async (calendarId: string, currentYear: number) => {
  const workingDays = await getWorkingDaysPreview(
    calendarId,
    moment([currentYear]),
    moment([currentYear + 1])
  )
  if (!workingDays) {
    return undefined
  }

  return {
    yearData: generateYearInformation(workingDays, currentYear),
    workingDaysCalendar: workingDays.workingDaysCalendar,
  }
}
