import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, Switch, useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'
import CalendarsPage from '../calendars/components/CalendarsPage'
import { CalendarType, calendarTypes } from '../calendars/models'
import { CarrierAccountsContainer } from '../carrier-configuration/components/CarrierAccountsContainer'
import { IncompleteCarrierAccountPage } from '../carrier-configuration/components/IncompleteCarrierAccountPage'
import { NetworkBreadCrumbItem } from '../common/components/RouterWithBreadcrumbs'
import EditLocationPageContainer from '../common/EditLocationPageContainer'
import LocationPermissionsEditor from '../permissions/components/LocationPermissionsEditor'
import { useAppContext } from '../common/AppContext'
import PackingFragment from './PackingFragment'
import FulfillerOverview from './components/view-location-page/FulfillerOverviewPage'
import LocationDetails from './components/view-location-page/LocationDetails'
import { useLogisticsLocation } from './LocationContext'
import LocationAdvancedOverview from './components/view-location-page/LocationAdvancedOverview'

export default function LocationPage() {
  const { containerType } = useAppContext()
  const { t } = useTranslation()
  const match = useRouteMatch()
  const { logisticsLocation } = useLogisticsLocation()

  const detailsTab = () => (
    <>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <NetworkBreadCrumbItem />
      {logisticsLocation.showFulfillerOverview ? (
        <FulfillerOverview />
      ) : (
        <LocationDetails />
      )}
    </>
  )

  const calendarsTab = (
    routerProps: RouteComponentProps<{ calendarType: CalendarType }>
  ) => {
    const calendarType: string = routerProps.match.params.calendarType

    // workaround to support old links from other services to work
    if (calendarType === 'year') {
      routerProps.match.params.calendarType = 'working-days'

      return (
        <>
          <BreadcrumbsItem to="/">
            {t('common.logisticsLocations')}
          </BreadcrumbsItem>
          <NetworkBreadCrumbItem />
          <CalendarsPage routerProps={routerProps} />
        </>
      )
    }

    if (calendarType === 'week') {
      routerProps.match.params.calendarType = 'pickup'

      return (
        <>
          <BreadcrumbsItem to="/">
            {t('common.logisticsLocations')}
          </BreadcrumbsItem>
          <NetworkBreadCrumbItem />
          <CalendarsPage routerProps={routerProps} />
        </>
      )
    }

    if (!calendarTypes.includes(routerProps.match.params.calendarType)) {
      routerProps.match.params.calendarType = 'pickup'
    }

    return (
      <>
        <BreadcrumbsItem to="/">
          {t('common.logisticsLocations')}
        </BreadcrumbsItem>
        <NetworkBreadCrumbItem />
        <CalendarsPage routerProps={routerProps} />
      </>
    )
  }

  const permissionsTab = () => (
    <>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <NetworkBreadCrumbItem />
      <LocationPermissionsEditor />
    </>
  )

  return (
    <main className={`App-content ${containerType} flex-vertical`}>
      <Switch>
        <Route
          path={`${match.url}/calendars/:calendarType`}
          render={calendarsTab}
        />
        <Route path={`${match.url}/calendars`} render={calendarsTab} />
        <Route path={`${match.url}/carrier-accounts`}>
          <CarrierAccountsContainer />
        </Route>
        <Route
          path={`${match.url}/incomplete-carrier-accounts/:incompleteCarrierAccountId`}
          component={IncompleteCarrierAccountPage}
        />
        <Route path={`${match.url}/user-management`} render={permissionsTab} />
        <Route
          path={`${match.url}/edit`}
          component={EditLocationPageContainer}
        />
        <Route path={`${match.url}/packing`}>
          <PackingFragment location={logisticsLocation} />
        </Route>
        <Route path={`${match.url}/advanced-overview`}>
          <LocationAdvancedOverview />
        </Route>
        <Route path={`${match.url}`} render={detailsTab} />
      </Switch>
    </main>
  )
}
