import { Loudspeaker } from '@cimpress-technology/react-loudspeaker'
import Header from '@cimpress-technology/react-platform-header'
import { getSettings } from '@cimpress-technology/react-platform-settings/lib/SettingsClient'
import IconView from '@cimpress-technology/react-streamline-icons/lib/IconView'
import IconViewOff from '@cimpress-technology/react-streamline-icons/lib/IconViewOff'
import { colors, CssLoader, Tooltip } from '@cimpress/react-components'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { hot } from 'react-hot-loader/root'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { ThroughProvider } from 'react-through'
import * as fulfillmentLocationsStore from '../../common/proxy/fulfillment-locations-store'
import i18n, { supportedLanguages } from '../../i18n'
import auth, { onTokenExpired } from '../auth'
import AuthorizationPage from '../AuthorizationPage'
import * as googleAnalytics from '../helpers/googleAnalytics'
import { hotjar } from '../hotjar'
import * as logger from '../logger'
import { reactSelectPortalId } from '../models'
import { isAdvancedUser } from '../proxy/user-store'
import * as UserContext from '../UserContext'
import { AdvancedUserContainer } from './AdvancedUserContainer'
import AnchorButton from './AnchorButton'
import './App.css'
import './colors.css'
import { Preferences } from './Preferences'
import { MainRouter } from './RouterWithBreadcrumbs'
import { SnackbarController } from './SnackbarController'
import Container from './Container'

interface State {
  authorized: boolean
  authorizing: boolean
  authorizationError?: any
  tokenExpired: boolean
  isAdvancedUser: boolean
  enableAdvancedUserToggle: boolean
}

class App extends React.Component<WithTranslation, State> {
  public readonly state: State = {
    authorized: false,
    authorizing: false,
    tokenExpired: false,
    isAdvancedUser: false,
    enableAdvancedUserToggle: false,
  }
  private mounted = false

  public async componentDidMount() {
    onTokenExpired(this.tokenExpired)
    if (!auth.isLoggedIn()) {
      this.setState({ authorizing: true })
      this.mounted = true
      auth
        .ensureAuthentication({
          nextUri: window.location.pathname + window.location.search,
        })
        .then(async (authorized: boolean) => {
          if (this.mounted) {
            await this.onUserAuthorized()
            this.setState({ authorized, authorizing: false })
          }
        })
        .catch((err: any) => {
          logger.logError('Authorization error on application load', err)
          if (this.mounted) {
            this.setState({ authorizationError: err })
          }
        })
    } else {
      await this.onUserAuthorized()
      this.setState({ authorized: true })
      await this.forceTokenRenewal()
      await loadCache()
    }
  }

  public componentWillUnmount() {
    this.mounted = false
  }

  public async componentDidUpdate() {
    if (this.state.tokenExpired) {
      const authorized = await this.forceTokenRenewal()
      this.setState({ authorized, tokenExpired: !authorized })
    }
  }

  public forceTokenRenewal = () => {
    return auth.ensureAuthentication({
      nextUri: window.location.pathname + window.location.search,
      forceLogin: true,
    })
  }

  public handleLogOut = () => {
    auth.logout('/')
  }

  public handleLogIn = () => {
    auth.login('/')
  }

  public render() {
    const loudspeakerConf = {
      language: this.props.i18n.language,
      title: this.props.t('loudspeaker.title'),
      channelIds: ['logistics-configuration'],
    }

    const appLinks = [
      {
        id: 'contact-us',
        content: <Link to="/contact-us">{this.props.t('contactUs')}</Link>,
      },
      {
        id: 'divider',
        content: (
          <AdvancedUserContainer>
            <div className="link-divider" />
          </AdvancedUserContainer>
        ),
      },
      {
        id: 'preferences',
        content: (
          <AdvancedUserContainer>
            <Preferences
              key="preferences"
              selectedLanguage={this.props.i18n.language}
            />
          </AdvancedUserContainer>
        ),
      },
    ]

    if (this.state.enableAdvancedUserToggle) {
      appLinks.push({
        id: 'advanced',
        content: (
          <AnchorButton
            className="clickable"
            style={{ padding: '0' }}
            onClick={this.toggleAdvancedUser}
          >
            <Tooltip
              contents={this.props.t('enableAdvancedUserToggleTooltip')}
              direction="left"
            >
              {this.state.isAdvancedUser ? (
                <IconViewOff
                  color={colors.ocean.base}
                  weight="regular"
                  style={{ margin: '16px' }}
                />
              ) : (
                <IconView
                  color={colors.ocean.base}
                  weight="regular"
                  style={{ margin: '16px' }}
                />
              )}
            </Tooltip>
          </AnchorButton>
        ),
      })
    }

    const context = { isAdvancedUser: this.state.isAdvancedUser }

    return (
      <Container>
        <UserContext.Provider value={context}>
          <ThroughProvider>
            <CssLoader>
              <Router>
                <>
                  <Loudspeaker
                    accessToken={auth.getAccessToken()}
                    language={loudspeakerConf.language}
                    title={loudspeakerConf.title}
                    channelIds={loudspeakerConf.channelIds}
                  />
                  <SnackbarController />
                  <div style={{ zIndex: 999 }}>
                    <Header
                      responsive={true}
                      appTitle={
                        <Link to="/">
                          <Trans i18nKey="title" />
                        </Link>
                      }
                      profile={auth.getProfile()}
                      isLoggedIn={auth.isLoggedIn()}
                      onLogInClicked={this.handleLogIn}
                      onLogOutClicked={this.handleLogOut}
                      appLinks={appLinks}
                    />
                  </div>
                  {this.state.authorized ? (
                    <MainRouter />
                  ) : (
                    <AuthorizationPage />
                  )}
                </>
              </Router>
            </CssLoader>
          </ThroughProvider>
          <ReactSelectMenuPortal />
        </UserContext.Provider>
      </Container>
    )
  }

  private toggleAdvancedUser = () => {
    this.setState({
      isAdvancedUser: !this.state.isAdvancedUser,
    })
  }

  private async onUserAuthorized() {
    const advancedUser = await isAdvancedUser()
    this.setState({
      isAdvancedUser: advancedUser,
      enableAdvancedUserToggle: advancedUser,
    })

    const settings = await getSettings(
      auth.getAccessToken(),
      supportedLanguages
    )
    i18n.changeLanguage(settings.global.language)
    googleAnalytics.initialize()
    const fullstory = (window as any).FS
    if (fullstory) {
      fullstory.identify(auth.getProfile().sub, {
        displayName: auth.getProfile().name,
        email: auth.getProfile().email,
      })
    }
    const isFromCimpress = auth.getProfile().email.endsWith('cimpress.com')

    if (!isFromCimpress) {
      hotjar(1680483, 6)
    }
  }

  private tokenExpired = () => {
    this.setState({ tokenExpired: true })
  }
}

function ReactSelectMenuPortal() {
  const menu = (
    <div
      id={reactSelectPortalId}
      className={reactSelectPortalId}
      style={{
        zIndex: 9999,
        position: 'absolute',
        top: '0px',
      }}
      tabIndex={-1}
    />
  )

  return ReactDOM.createPortal(menu, document.body)
}

async function loadCache() {
  await fulfillmentLocationsStore.getAll()
}

export default hot(withTranslation()(App))
