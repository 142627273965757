import axios from 'axios'
import { Moment } from 'moment-timezone'

const SCS = 'https://api.shipping.cimpress.io'

interface SapiLocationCarrierAccount {
  locationKey: string
  carrierAccountKey: string
  settings: {
    schedule: {
      id: string
    }
    carrierServices: {
      [csKey: string]: {
        schedule?: { id: string }
      }
    }
  }
}

export async function getLocationCarrierAccounts(
  accessToken: string,
  correlationId: string,
  locationId: string
): Promise<SapiLocationCarrierAccount[]> {
  const response = await axios.get(
    `${SCS}/v3/locations/${locationId}/carrier-accounts`,
    {
      headers: {
        authorization: accessToken,
        'x-request-id': correlationId,
      },
    }
  )

  return response.data.locationCarrierAccounts
}

interface Schedule {
  id: string
  calendarId?: string
}
export async function getSchedule(
  accessToken: string,
  correlationId: string,
  scheduleId: string
): Promise<Schedule> {
  return (
    await axios.get(`${SCS}/v3/schedules/${scheduleId}`, {
      headers: {
        authorization: accessToken,
        'x-request-id': correlationId,
      },
    })
  ).data
}

interface Pickup {
  id: string
  scheduleId: string
  tags: string[]
  time: string
  status: string
}

export async function getPickups(
  accessToken: string,
  correlationId: string,
  scheduleId: string,
  startTime: Moment,
  endTime: Moment
): Promise<Pickup[]> {
  const response = await axios.get(`${SCS}/v3/pickups`, {
    headers: {
      authorization: accessToken,
      'x-request-id': correlationId,
    },
    params: {
      scheduleId,
      since: startTime.format(),
      until: endTime.format(),
      limit: 50,
    },
  })

  return response.data.pickups
}

interface Summary {
  totalShipmentCount: number
  maxPageLimit: number
}

export async function getShipmentSummary(
  accessToken: string,
  correlationId: string,
  pickupId: string
): Promise<Summary> {
  const response = await axios.get(`${SCS}/v3/shipments/summary`, {
    headers: {
      authorization: accessToken,
      'x-request-id': correlationId,
    },
    params: {
      pickupId,
    },
  })

  return response.data
}
