import * as React from 'react'
import * as client from '@cimpress-technology/logistics-configuration-client'
import { v4 } from 'uuid'
import { useLogisticsLocation } from '../../../locations/LocationContext'
import {
  CalendarType,
  CountryCalendarCustomData,
  LastAccessedList,
  COUNTRY_CALENDARS_CUSTOM_DATA_ID,
} from '../../models'
import { bearerToken } from '../../../common/auth'
import { CountryCalendarContextProvider } from './CountryCalendarContext'
import CountryCalendarView from './CountryCalendarView'

interface Props {
  changeCalendarView(type: CalendarType): void
}

export function CountryCalendarContainer(props: Props) {
  const { logisticsLocation } = useLogisticsLocation()
  const [loading, setLoading] = React.useState(false)
  const [selectedCountry, setSelectedCountry] = React.useState(
    logisticsLocation.address.countryCode
  )
  const [
    countryCalendarCustomData,
    setCountryCalendarCustomData,
  ] = React.useState<CountryCalendarCustomData>({
    data: {
      lastAccessed: [],
    },
  })

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const result = await client.locations.getCustomData<LastAccessedList>(
        bearerToken(),
        v4(),
        logisticsLocation.id,
        COUNTRY_CALENDARS_CUSTOM_DATA_ID
      )
      const customData = result ?? { data: { lastAccessed: [] } }
      setCountryCalendarCustomData(customData)
      setSelectedCountry(
        customData.data.lastAccessed[0] ?? logisticsLocation.address.countryCode
      )
      setLoading(false)
    }

    fetchData()
  }, [logisticsLocation.address.countryCode, logisticsLocation.id])

  const context = {
    countryCalendarMapping: logisticsLocation.countryCalendars.mapping,
    selectedCountry,
    changeSelectedCountry: async (countryCode: string) => {
      setSelectedCountry(countryCode)
      const updatedLastAccess = {
        lastAccessed: [
          countryCode,
          ...countryCalendarCustomData.data.lastAccessed.filter(
            la => la !== countryCode
          ),
        ],
      }
      const etag = await client.locations.putCustomData(
        bearerToken(),
        v4(),
        logisticsLocation.id,
        COUNTRY_CALENDARS_CUSTOM_DATA_ID,
        updatedLastAccess,
        countryCalendarCustomData.etag
      )

      setCountryCalendarCustomData({ data: updatedLastAccess, etag })
    },
    lastAccessedCalendars: countryCalendarCustomData.data.lastAccessed,
    loadingCountryCalendars: loading,
  }

  return (
    <CountryCalendarContextProvider value={context}>
      <CountryCalendarView changeCalendarView={props.changeCalendarView} />
    </CountryCalendarContextProvider>
  )
}
