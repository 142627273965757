import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useParams, useRouteMatch } from 'react-router'
import Preloader from '../common/components/Preloader'
import FourOhFourPage from '../common/FourOhFourPage'
import * as models from '../common/models'
import { getNetwork } from '../common/proxy/locations-store'
import NetworkPermissionsEditor from '../permissions/components/NetworkPermissionsEditor'
import { useAppContext } from '../common/AppContext'
import NetworkDetails from './components/NetworkDetails'
import NetworkCalendarsPage from './NetworkCalendarsPage'

export default function ViewNetworkPage() {
  const { containerType } = useAppContext()
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(true)
  const [network, setNetwork] = React.useState<models.Network>()
  const [forceReload, setForceReload] = React.useState(false)
  const params = useParams<{ networkId: string }>()
  const match = useRouteMatch()

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const fetchedNetwork = await getNetwork(params.networkId)

      if (!fetchedNetwork) {
        return
      }

      setNetwork(fetchedNetwork)
      setLoading(false)
    }
    fetchData()
  }, [params.networkId, forceReload])

  if (loading) {
    return <Preloader />
  }

  if (!network) {
    return <FourOhFourPage />
  }

  const reloadNetwork = () => {
    setForceReload(!forceReload)
  }

  return (
    <main className={`App-content flex-vertical ${containerType}`}>
      <Switch>
        <Route path={`${match.path}/calendars`}>
          <BreadcrumbsItem to="/">
            {t('common.logisticsLocations')}
          </BreadcrumbsItem>
          <BreadcrumbsItem to={`/network/${network.apiNetwork.id}`}>
            {network.apiNetwork.name}
          </BreadcrumbsItem>
          <BreadcrumbsItem to="#">
            {t('common.calendars.calendars')}
          </BreadcrumbsItem>
          <NetworkCalendarsPage
            network={network}
            reloadNetwork={reloadNetwork}
          />
        </Route>
        <Route path={`${match.path}/user-management`}>
          <BreadcrumbsItem to="/">
            {t('common.logisticsLocations')}
          </BreadcrumbsItem>
          <NetworkPermissionsEditor network={network} />
        </Route>
        <Route path={`${match.path}`}>
          <NetworkDetails network={network} reloadNetwork={reloadNetwork} />
        </Route>
      </Switch>
    </main>
  )
}
