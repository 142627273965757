import { locations } from '@cimpress-technology/logistics-configuration-client'
import Icon from '@cimpress-technology/react-streamline-icons/lib/IconEmailActionUnread'
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle'
import { Table, Tooltip } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Preloader from '../../common/components/Preloader'
import { toPercent } from '../../common/proxy/cimba-store'
import styles from './LocationsTable.module.css'

interface Row {
  contact: locations.models.Contact
  id: string
  name: string
  shipments: number
  onTimeToShip: number
  onTimeToCustomer: number
}

interface Props {
  shipmentsPerLocation: Map<string, number>
  locationOtcMap: Map<string, number>
  locationOtsMap: Map<string, number>
  loading: boolean
  locations: locations.models.LocationWithLinks[]
}

export default function LocationsTable(props: Props) {
  const { t } = useTranslation()

  if (props.loading) {
    return <Preloader />
  }

  const columns = [
    {
      Header: (
        <span className={styles.withSortable}>{t('locations.location')}</span>
      ),
      id: 'location',
      accessor: (row: Row) => row,
      Cell: (cellProps: { value: Row }) => (
        <Link to={`/location/${cellProps.value.id}`}>
          {cellProps.value.name}
        </Link>
      ),
      sortMethod: (a: Row, b: Row) => a.name.localeCompare(b.name),
    },
    {
      Header: (
        <span className={styles.withSortable}>
          {t('networks.locations.numberOfShipments')}
        </span>
      ),
      id: 'shipments',
      accessor: (location: Row) => {
        return location.shipments
      },
      className: 'text-align-right',
      width: 126,
    },
    {
      Header: (
        <div className={styles.withSortable}>
          {t('networks.shippingOverview.onTimetoShip.title')}{' '}
          <Tooltip
            direction="right"
            contents={t('networks.shippingOverview.onTimetoShip.tooltip')}
          >
            <IconInformationCircle weight="fill" />
          </Tooltip>
        </div>
      ),
      id: 'onTimeToShip',
      accessor: (location: Row) => {
        return location.onTimeToShip
      },
      Cell: PercentageCell,
      className: 'text-align-right',
      width: 150,
    },
    {
      Header: (
        <div className={styles.withSortable}>
          {t('networks.shippingOverview.onTimetoCustomer.title')}{' '}
          <Tooltip
            direction="right"
            contents={t('networks.shippingOverview.onTimetoCustomer.tooltip')}
          >
            <IconInformationCircle weight="fill" />
          </Tooltip>
        </div>
      ),
      id: 'onTimeToCustomer',
      accessor: (location: Row) => {
        return location.onTimeToCustomer
      },
      Cell: PercentageCell,
      className: 'text-align-right',
      width: 180,
    },
    {
      Header: '',
      id: 'actions',
      width: 60,
      sortable: false,
      accessor: (location: Row) => {
        return location.contact && location.contact.email ? (
          <a
            className="pull-right"
            href={`mailto:${location.contact.email}`}
            target="blank"
          >
            <Icon size="lg" />
          </a>
        ) : (
          ''
        )
      },
    },
  ]

  const data: Row[] = props.locations.map(l => {
    return {
      contact: l.contact,
      id: l.id,
      name: l.name,
      shipments: props.shipmentsPerLocation.get(l.id) || 0,
      onTimeToShip: props.locationOtsMap.get(l.id) || -1,
      onTimeToCustomer: props.locationOtcMap.get(l.id) || -1,
    }
  })

  return (
    <Table
      noDataText={t('locations.noLocationsFound')}
      pageSize={data.length ? data.length : undefined}
      resizable={false}
      sortable={true}
      showPagination={false}
      columns={columns}
      data={data}
      defaultSorted={[{ id: 'shipments', desc: true }]}
    />
  )
}

function PercentageCell(cellProps: { value: number }) {
  return cellProps.value === -1 ? 'NA' : toPercent(cellProps.value)
}
