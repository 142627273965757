import { colors, Tooltip } from '@cimpress/react-components'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AdvancedUserContainer } from '../../../../common/components/AdvancedUserContainer'
import AnchorButton from '../../../../common/components/AnchorButton'
import { Location } from '../../../../common/models'
import { EnhancedCaasProfile } from '../../../../common/models-carriers'
import { getCaasProfiles } from '../../../../common/proxy/carrier-services-store'
import { useLogisticsLocation } from '../../../../locations/LocationContext'
import { PickupCalendarDaysWithLegend } from '../../../models'
import useWeeklyCalendarContext from '../WeeklyCalendarContext'
import PickupCalendarAddModalContainer from './pickup-calendar-editor/PickupCalendarAddModalContainer'
import PickupCalendarEditModalContainer from './pickup-calendar-editor/PickupCalendarEditModalContainer'
import { PickupCalendarLegendRow } from './PickupCalendarLegendRow'
import './PickupCalendarsLegend.css'

export function PickupCalendarsLegend(): JSX.Element {
  const { t } = useTranslation()
  const context = useWeeklyCalendarContext()
  const { logisticsLocation } = useLogisticsLocation()

  const [showAddModal, openAddModal, closeAddModal] = useShowModal()
  const [showEditModal, openEditModal, closeEditModal] = useShowModal()

  const [caasProfiles, setCaasProfiles] = React.useState<EnhancedCaasProfile[]>(
    []
  )
  const [pickupCalendarId, setPickupCalendarId] = React.useState<
    string | undefined
  >()

  const [loading, setLoading] = React.useState(true)

  const onEdit = (currentPickupCalendarId: string) => {
    setPickupCalendarId(currentPickupCalendarId)
    openEditModal()
  }

  React.useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      const profiles = await getCaasProfiles()
      if (mounted) {
        setCaasProfiles(profiles)
        setLoading(false)
      }
    }
    fetchData()

    return () => {
      mounted = false
    }
  }, [logisticsLocation])

  const carrierServicesToCalendarNames = mapCarrierServicesToPickupCalendarNames(
    logisticsLocation,
    context.pickupCalendarDaysWithLegend
  )

  const AddCalendarButton = () => {
    const fallback = (
      <Tooltip
        contents={
          <Trans
            i18nKey={
              'calendars.weekly.pickupCalendarsLegend.contactSupportToAddCalendar'
            }
          >
            Please contact{' '}
            <a href="mailto:logisticssupport@cimpress.com">
              logisticssupport@cimpress.com
            </a>{' '}
            to create a pickup schedule.
          </Trans>
        }
        key="message-tooltip"
      >
        <AnchorButton>
          <i className="fa fa-plus-circle" />
        </AnchorButton>
      </Tooltip>
    )

    return (
      <AdvancedUserContainer fallback={fallback} enableTooltip={true}>
        <AnchorButton onClick={openAddModal}>
          <i className="fa fa-plus-circle" />
        </AnchorButton>
      </AdvancedUserContainer>
    )
  }

  const ModalProps = {
    caasProfiles,
    carrierServicesToCalendarNames,
  }

  return (
    <>
      <span className="with-button-on-right">
        <h5>{t('common.calendars.pickupCalendars')}</h5>
        {context.canAddCalendar && (
          <span className="button-on-right">
            <AddCalendarButton />
          </span>
        )}
      </span>
      <PickupCalendarsLegendList onEdit={onEdit} loading={loading} />
      {showAddModal && (
        <PickupCalendarAddModalContainer
          onClose={closeAddModal}
          {...ModalProps}
        />
      )}
      {showEditModal && (
        <PickupCalendarEditModalContainer
          onClose={closeEditModal}
          pickupCalendarId={pickupCalendarId!}
          {...ModalProps}
        />
      )}
    </>
  )
}

interface PickupCalendarsLegendListProps {
  onEdit: (pickupCalendarId: string) => void
  loading: boolean
}

export function PickupCalendarsLegendList(
  props: PickupCalendarsLegendListProps
): JSX.Element {
  const { t } = useTranslation()
  const context = useWeeklyCalendarContext()

  const selectAll = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    context.onPickupCalendarSelectAll()
  }

  const deselectAll = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    context.onPickupCalendarDeselectAll()
  }

  const legendPickupCalendars = context.pickupCalendarDaysWithLegend.map(
    pc => pc.pickupCalendarDisplay
  )

  if (legendPickupCalendars.length === 0) {
    return (
      <div
        className="text-muted"
        style={{
          marginBottom: '16px',
          textAlign: 'center',
          color: colors.slate,
        }}
      >
        <small>
          <AdvancedUserContainer
            enableTooltip={true}
            fallback={t(
              'calendars.weekly.pickupCalendarsLegend.noPickupCalendars'
            )}
          >
            <Trans i18nKey="calendars.weekly.pickupCalendarsLegend.noPickupCalendars">
              You don't have any pickup schedules.
            </Trans>
          </AdvancedUserContainer>
        </small>
      </div>
    )
  }

  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <AnchorButton onClick={selectAll}>
          {t('calendars.weekly.pickupCalendarsLegend.selectAll')}
        </AnchorButton>
        {' / '}
        <AnchorButton onClick={deselectAll}>
          {t('calendars.weekly.pickupCalendarsLegend.clear')}
        </AnchorButton>
      </div>
      <div style={{ minHeight: '0' }}>
        <div className="scrollWrapper">
          <div className="pickupCalendarList">
            <ul className="fa-ul">
              {legendPickupCalendars
                .sort((a, b) => a.calendar.name.localeCompare(b.calendar.name))
                .map(pc => (
                  <PickupCalendarLegendRow
                    loading={props.loading}
                    pickupCalendarDisplay={pc}
                    key={pc.calendar.id}
                    onEdit={props.onEdit}
                  />
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

function mapCarrierServicesToPickupCalendarNames(
  location: Location,
  pickupCalendars: PickupCalendarDaysWithLegend[]
) {
  return Object.keys(location.pickupCalendars.mapping).reduce((acc, curr) => {
    const calendar = pickupCalendars.find(pc => {
      return (
        pc.pickupCalendarDisplay.calendar.id ===
        location.pickupCalendars.mapping[curr].id
      )
    })

    if (calendar) {
      acc[curr] = calendar.pickupCalendarDisplay.calendar.name
    }

    return acc
  }, {} as any)
}

function useShowModal(): [boolean, () => void, () => void] {
  const [showModal, setShowModal] = React.useState(false)

  const open = () => setShowModal(true)
  const close = () => setShowModal(false)

  return [showModal, open, close]
}
