import { calendars } from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { getTransitCalendars } from '../calendars/components/transit-calendar-commons'
import TransitCalendarContainer from '../calendars/components/TransitCalendarContainer'
import { CalendarType, CreateTransitCalendar } from '../calendars/models'
import auth from '../common/auth'
import {
  getDefaultWorkingDaysCalendar,
  filterCarrierServicesForCalendar,
  addCalendarToMapping,
} from '../common/proxy/calendars-store'
import { getCaasProfiles } from '../common/proxy/carrier-services-store'
import { useLogisticsLocation } from './LocationContext'
import ReadOnlyCalendarMessage from './ReadOnlyCalendarMessage'

interface Props {
  changeCalendarView(type: CalendarType): void
}

export default function LocationTransitCalendarContainer(props: Props) {
  const {
    logisticsLocation,
    updateLocation: updateLogisticsLocation,
    isNetworkAdmin,
  } = useLogisticsLocation()

  const updateLocation = async (
    calendarId: string,
    carrierServices: string[]
  ) => {
    await updateLogisticsLocation(locationToUpdate => {
      filterCarrierServicesForCalendar(
        locationToUpdate.transitCalendars.mapping,
        calendarId,
        carrierServices
      )
      addCalendarToMapping(
        locationToUpdate.transitCalendars.mapping,
        calendarId,
        carrierServices
      )
    })
  }

  const createTransitCalendar = async (
    data: CreateTransitCalendar,
    correlationId: string
  ) => {
    const wdCalendar = getDefaultWorkingDaysCalendar(
      logisticsLocation.localeSettings.timezone,
      logisticsLocation.address.countryCode,
      logisticsLocation.id
    )
    wdCalendar.weeklySchedules[0].schedule = data.weeklySchedule

    const transitCalendar = {
      ...wdCalendar,
      name: data.calendarName,
    }
    const transitCalendarId = await calendars.createWorkingDaysCalendar(
      auth.getAccessToken(),
      correlationId,
      transitCalendar
    )

    return transitCalendarId
  }

  const getLocationCaasProfiles = () => {
    return getCaasProfiles()
  }

  const currentNetwork =
    logisticsLocation._links && logisticsLocation._links.network

  return (
    <TransitCalendarContainer
      transitCalendars={getTransitCalendars(
        logisticsLocation.transitCalendars.mapping
      )}
      getCaasProfiles={getLocationCaasProfiles}
      changeCalendarView={props.changeCalendarView}
      updateOwner={updateLocation}
      createTransitCalendar={createTransitCalendar}
      forceDisabled={currentNetwork && Boolean(currentNetwork.id)}
      canCreate={logisticsLocation.editable}
    >
      {currentNetwork && (
        <ReadOnlyCalendarMessage
          network={currentNetwork}
          isNetworkAdmin={isNetworkAdmin}
        />
      )}
    </TransitCalendarContainer>
  )
}
