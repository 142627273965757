import { LogisticsNetworkWithLinks } from '@cimpress-technology/logistics-configuration-client/js/models/locations'
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle'
import { Tooltip } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Preloader from '../../common/components/Preloader'
import {
  getOnTimetoCustomerPercentage,
  getOnTimetoShipPercentage,
} from '../../common/proxy/cimba-store'
import styles from './ShippingOverview.module.css'

export interface Props {
  network: LogisticsNetworkWithLinks
  totalShipments: number
  loading: boolean
}
export function ShippingOverview(props: Props) {
  const { t } = useTranslation()
  const [onTimetoShipPercentage, setOnTimetoShipPercentage] = React.useState('')
  const [
    onTimetoCustomerPercentage,
    setOnTimetoCustomerPercentage,
  ] = React.useState('')
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const [onTimetoShip, onTimetoCustomer] = await Promise.all([
        getOnTimetoShipPercentage({
          networkId: props.network.id,
        }),
        getOnTimetoCustomerPercentage({
          networkId: props.network.id,
        }),
      ])

      setOnTimetoShipPercentage(onTimetoShip)
      setOnTimetoCustomerPercentage(onTimetoCustomer)
      setLoading(false)
    }
    fetchData()
  }, [props.network])

  const totalShipmentsHeader = props.loading ? (
    <Preloader small={true} />
  ) : isNaN(props.totalShipments) ? (
    'NA'
  ) : (
    props.totalShipments
  )
  const onTimetoShipPercentageHeader = loading ? (
    <Preloader small={true} />
  ) : (
    onTimetoShipPercentage
  )
  const onTimetoCustomerPercentageHeader = loading ? (
    <Preloader small={true} />
  ) : (
    onTimetoCustomerPercentage
  )

  return (
    <div className={`card card-minimal ${styles.marginBottomFull}`}>
      <div className="card-header">
        <h5 className="card-title">{t('networks.shippingOverview.title')}</h5>
      </div>
      <div className="card-block">
        <div className="row">
          <div className={`col-xs-12 col-md-4 ${styles.textAlignCenter}`}>
            <h1 className={`${styles.value}`}>{totalShipmentsHeader}</h1>
            <div className={`${styles.title}`}>
              {t('networks.shippingOverview.shipments')}
            </div>
          </div>
          <div className={`col-xs-6 col-md-4 ${styles.textAlignCenter}`}>
            <h1 className={`${styles.value}`}>
              {onTimetoShipPercentageHeader}
            </h1>
            <div className={`${styles.title}`}>
              {t('networks.shippingOverview.onTimetoShip.title')}{' '}
              <Tooltip
                direction="right"
                contents={t('networks.shippingOverview.onTimetoShip.tooltip')}
              >
                <IconInformationCircle weight="fill" />
              </Tooltip>
            </div>
          </div>
          <div className={`col-xs-6 col-md-4 ${styles.textAlignCenter}`}>
            <h1 className={`${styles.value}`}>
              {onTimetoCustomerPercentageHeader}
            </h1>
            <div className={`${styles.title}`}>
              {t('networks.shippingOverview.onTimetoCustomer.title')}{' '}
              <Tooltip
                direction="right"
                contents={t(
                  'networks.shippingOverview.onTimetoCustomer.tooltip'
                )}
              >
                <IconInformationCircle weight="fill" />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
