import { centralizedAuth } from '@cimpress/simple-auth-wrapper'

export const CLIENT_ID = 'iidGCVqKzo6O2yuApIkp9tUbzKWQpHxY'
const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
  emitInitialTokenExpired: false,
  expirationOffset: 60, // fire expiration event 60 seconds before the token expires
})

export function onTokenExpired(callback: () => void) {
  auth.on('tokenExpired', callback)
}

export default auth

export function bearerToken() {
  return `Bearer ${auth.getAccessToken()}`
}
