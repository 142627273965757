import { Select } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CarrierSelection } from '../../../../../carrier-configuration/components/add-carrier-flow/carrierAccountData'
import { ValidationContainer } from '../../../../../common/components/ValidationContainer'
import { ValidationResult } from '../../../../../common/helpers/validation'
import { Option, reactSelectPortalId } from '../../../../../common/models'
import { EnhancedCaasProfile } from '../../../../../common/models-carriers'
import { filterAvailableCaasProfiles } from '../../../../../common/proxy/carrier-services-store'
import { getCarrierServicesOptions } from './carrier-services-options'

interface Props {
  calendarName?: string
  carrierServicesToCalendarNames: { [key: string]: string }
  caasProfiles: EnhancedCaasProfile[]
  data: CarrierSelection
  carrierServicesValidation: ValidationResult
  onChange(data: CarrierSelection): void
}

export default function CarrierServicesSelector(props: Props) {
  const { t } = useTranslation()

  const filteredCaasProfiles = filterAvailableCaasProfiles(props.caasProfiles)

  const onCarrierServicesChange = (changeData: Option[]) => {
    props.onChange({
      carrier: props.data.carrier,
      carrierServices: changeData.map(cd => cd.value as string),
    })
  }

  const options = props.data.carrier
    ? getCarrierServicesOptions(
        t,
        props.data,
        filteredCaasProfiles,
        props.carrierServicesToCalendarNames,
        props.data.carrier,
        props.calendarName
      )
    : []

  return (
    <ValidationContainer validationResult={props.carrierServicesValidation}>
      <Select
        label={t('carrierAccounts.addCarrierFlow.selectCarrierServicesLabel')}
        options={options}
        required={true}
        isDisabled={!props.data.carrier}
        value={options.filter(o =>
          (props.data.carrierServices || []).includes(o.value)
        )}
        isMulti={true}
        onChange={onCarrierServicesChange}
        menuPortalTarget={document.getElementById(reactSelectPortalId)}
      />
    </ValidationContainer>
  )
}
