import * as React from 'react'
import { models } from '@cimpress-technology/coam-sapidus'
import { audits } from '@cimpress-technology/logistics-configuration-client'
import * as uuid from 'uuid'
import * as jsonPatch from 'fast-json-patch'
import { AuditRow } from '@cimpress-technology/logistics-configuration-client/js/models/audits'
import { Link } from 'react-router-dom'
import Preloader from '../../../common/components/Preloader'
import MultiResourceAuditTable, {
  LocationResourceTypeArray,
} from '../../../audits/components/MultiResourceAuditTable'
import { useLogisticsLocation } from '../../LocationContext'
import { bearerToken } from '../../../common/auth'
import { AuditResource } from '../../../common/models'

type LocationResourceTypes = typeof LocationResourceTypeArray[number]

interface ResourceWithLink {
  link: React.ReactNode
  type: LocationResourceTypes
  resource: AuditResource
}

export default function AdvancedAudit() {
  const { logisticsLocation } = useLogisticsLocation()
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState<
    (AuditRow & { link: React.ReactNode; patch?: jsonPatch.Operation[] })[]
  >([])
  const pickupCalendarIds = new Set<string>()

  Object.values(logisticsLocation.pickupCalendars.mapping).forEach(ref => {
    pickupCalendarIds.add(ref.id)
    if (ref.additionalCalendars) {
      ref.additionalCalendars.forEach(additionalRef =>
        pickupCalendarIds.add(additionalRef.id)
      )
    }
  })

  const countryCalendarIds = new Set<string>()

  Object.values(logisticsLocation.countryCalendars.mapping).forEach(ref => {
    if (!ref.default) {
      countryCalendarIds.add(ref.id)
    }
  })

  const transitCalendarIds = new Set<string>()

  Object.values(logisticsLocation.transitCalendars.mapping).forEach(ref => {
    transitCalendarIds.add(ref.id)
  })

  const resources: ResourceWithLink[] = [
    {
      link: <Link to={`/location/${logisticsLocation.id}`}>Location</Link>,
      type: 'Location',
      resource: {
        resourceId: logisticsLocation.id,
        resourceType: models.ResourceTypes.LogisticsLocation,
      },
    },
    {
      link: <Link to="calendars/working-days">Working Days Calendar</Link>,
      type: 'Working days calendar',
      resource: {
        resourceId: logisticsLocation.workingDaysCalendar.id,
        resourceType: models.ResourceTypes.Calendar,
      },
    },
    ...Array.from(pickupCalendarIds).map<ResourceWithLink>(id => ({
      link: (
        <Link to={`calendars/pickup?pickupCalendar=${id}`}>
          Pickup Calendar
        </Link>
      ),
      type: 'Pickup calendar',
      resource: {
        resourceId: id,
        resourceType: models.ResourceTypes.Calendar,
      },
    })),
    ...Array.from(countryCalendarIds).map<ResourceWithLink>(id => ({
      link: <Link to="calendars/country">Country Calendar</Link>,
      type: 'Country calendar',
      resource: {
        resourceId: id,
        resourceType: models.ResourceTypes.Calendar,
      },
    })),
    ...Array.from(transitCalendarIds).map<ResourceWithLink>(id => ({
      link: (
        <Link to={`calendars/transit?calendar=${id}`}>Transit Calendar</Link>
      ),
      type: 'Transit calendar',
      resource: {
        resourceId: id,
        resourceType: models.ResourceTypes.Calendar,
      },
    })),
    {
      link: (
        <Link to={`/location/${logisticsLocation.id}/user-management`}>
          User Management
        </Link>
      ),
      type: 'User Management',
      resource: {
        resourceId: logisticsLocation.id,
        resourceType: audits.models.AuditResources.LogisticsLocationPermissions,
      },
    },
  ]

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const allAuditRows = await Promise.all(
        resources.map(r =>
          audits.getActions(
            bearerToken(),
            uuid.v4(),
            r.resource.resourceType,
            r.resource.resourceId
          )
        )
      )
      setData(
        allAuditRows
          .flatMap(audit => postProcess(audit))
          .map(row => ({
            ...row,
            link: resources.find(r => r.resource.resourceId === row.resource_id)
              ?.link,
            type: resources.find(r => r.resource.resourceId === row.resource_id)
              ?.type,
          }))
      )
      setLoading(false)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <Preloader />
  }

  return (
    <div className="row">
      <div className="col-xs-12">
        <MultiResourceAuditTable data={data} />
      </div>
    </div>
  )
}

function postProcess<
  T extends { data: any; resource_type: string; action: string }
>(rows: T[]): (T & { patch?: any[] })[] {
  if (rows.length === 0) {
    return []
  }

  switch (rows[0].resource_type) {
    case 'logistics-location-permissions':
    case 'logistics-network-permissions':
      return rows.map(p => ({
        ...p,
        action: p.action === 'POST' ? 'ADD USER' : 'DELETE USER',
        patch: p.data,
        data: undefined,
      }))

    default:
      return getDiff(rows)
  }
}

function getDiff<T extends { data: any }>(
  data: T[]
): (T & { patch?: any[] })[] {
  return data.reduceRight((acc, curr, index, array) => {
    if (index === array.length - 1) {
      return [{ ...curr, patch: undefined }]
    }

    const prev = array[index + 1]
    const patch = jsonPatch.compare(prev.data, curr.data)

    return [{ ...curr, patch }, ...acc]
  }, [])
}
