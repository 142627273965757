import { Alert, Button, Modal, TextField } from '@cimpress/react-components'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CarrierSelection } from '../../carrier-configuration/components/add-carrier-flow/carrierAccountData'
import AnchorButton from '../../common/components/AnchorButton'
import { ValidationContainer } from '../../common/components/ValidationContainer'
import {
  toBSStyle,
  validateField,
  validateList,
  ValidationStatus,
} from '../../common/helpers/validation'
import { EnhancedCaasProfile } from '../../common/models-carriers'
import {
  carrierServicesValidationField,
  carrierValidationField,
  EditCalendar,
  EditCalendarValidation,
  nameValidationField,
} from '../models'
import styles from './CalendarEditModal.module.css'
import CarrierServicesSelector from './weekly/legend/pickup-calendar-editor/CarrierServicesSelector'

interface Props {
  title: string
  children?: React.ReactNode
  editCalendar: EditCalendar
  saving: boolean
  carrierServicesToCalendarNames: { [key: string]: string }
  carrierAccountTypes: EnhancedCaasProfile[]
  editable: boolean

  onSave: (data: EditCalendar) => void
  onClose: () => void
}
export default function CalendarEditModal(props: Props) {
  const { t } = useTranslation()
  const [calendarName, setCalendarName] = React.useState(
    props.editCalendar.calendarName
  )

  const [carrierSelection, setCarrierSelection] = React.useState<
    CarrierSelection
  >({ carrier: props.editCalendar.carrierSelection.carrier })

  const [validation, setValidation] = React.useState<EditCalendarValidation>(
    getInitialEditCalendarValidationStatus()
  )

  const [showCarrierSelector, setShowCarrierSelector] = React.useState(false)
  const onAddServices = () => {
    setShowCarrierSelector(true)
  }

  const onSave = () => {
    const newValidationState = {
      name: validateField(nameValidationField, calendarName),
      carrier: validateField(
        carrierValidationField,
        props.editCalendar.carrierSelection.carrier
      ),
      carrierServices: validateList(
        carrierServicesValidationField,
        props.editCalendar.carrierSelection.carrierServices
      ),
    }

    setValidation(newValidationState)

    const isCalendarValid = Object.values(newValidationState).every(
      field => field.status === ValidationStatus.Valid
    )

    if (isCalendarValid) {
      props.onSave({
        calendarName,
        carrierSelection: {
          carrier: carrierSelection.carrier,
          carrierServices: [
            ...(carrierSelection.carrierServices || []),
            ...(props.editCalendar.carrierSelection.carrierServices || []),
          ],
        },
      })

      props.onClose()
    }
  }

  const footer = props.editable ? (
    <>
      <Button
        type="default"
        onClick={props.onClose}
        key="cancel-btn"
        disabled={props.saving}
      >
        {t('common.cancel')}
      </Button>
      <Button
        type="primary"
        onClick={onSave}
        key="save-btn"
        disabled={props.saving}
      >
        {`${props.saving ? t('common.saving') : t('common.saveChanges')}`}
      </Button>
    </>
  ) : (
    <Button
      type="primary"
      onClick={props.onClose}
      key="save-btn"
      disabled={props.saving}
    >
      {t('common.close')}
    </Button>
  )

  const onNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCalendarName(e.currentTarget.value)
    setValidation({
      ...validation,
      name: validateField(nameValidationField, e.currentTarget.value),
    })
  }

  const onCarrierAccountDataChange = (
    newCarrierAccountData: CarrierSelection
  ) => {
    setCarrierSelection(newCarrierAccountData)
    setValidation({
      ...validation,
      carrierServices: validateList(
        carrierServicesValidationField,
        newCarrierAccountData.carrierServices
      ),
    })
  }

  const carrierAccountType = props.carrierAccountTypes.find(
    cat => cat.key === carrierSelection.carrier
  )
  const carrierName = carrierAccountType ? carrierAccountType.name : ''

  const carrierServices =
    props.editCalendar.carrierSelection.carrierServices || []
  const carrierServiceNames = carrierAccountType
    ? carrierAccountType.carrierServices
        .filter(cs => carrierServices.includes(cs.key))
        .map(cs => cs.name)
    : carrierServices

  return (
    <Modal
      show={true}
      onRequestHide={props.onClose}
      closeButton={true}
      closeOnOutsideClick={false}
      title={props.title}
      style={{ width: '526px' }}
      footer={footer}
    >
      <ValidationContainer validationResult={validation.name}>
        <TextField
          name="name"
          label={t('calendars.weekly.addPickupCalendarModal.scheduleName')}
          value={calendarName}
          onChange={onNameChange}
          autoFocus={true}
          required={true}
          bsStyle={toBSStyle(validation.name)}
          maxLength={100}
          disabled={!props.editable}
        />
      </ValidationContainer>
      {props.children ? (
        props.children
      ) : (
        <div style={{ marginTop: '20px', marginBottom: '12px' }}>
          <h5>{t('calendars.weekly.addPickupCalendarModal.applyTo')}</h5>
          <div className={styles.carrierInfo}>
            <div className="h7">{t('common.carrier')}</div>
            <div>{carrierName}</div>
            <div className="h7">{t('common.carrierServices')}</div>
            <div>{carrierServiceNames.join(', ')}</div>
            {!showCarrierSelector && (
              <>
                <div />
                <div style={{ marginTop: '4px' }}>
                  <AnchorButton
                    onClick={props.editable ? onAddServices : undefined}
                  >
                    {t('calendars.addServices')}
                  </AnchorButton>
                </div>
              </>
            )}
          </div>
          {showCarrierSelector && (
            <CarrierServicesSelector
              carrierServicesToCalendarNames={
                props.carrierServicesToCalendarNames
              }
              caasProfiles={props.carrierAccountTypes}
              data={carrierSelection}
              onChange={onCarrierAccountDataChange}
              carrierServicesValidation={validation.carrierServices}
            />
          )}
          <Alert
            type="info"
            dismissible={false}
            message={
              <Trans i18nKey="calendars.noCarrierServiceRemovalMessage">
                You can't change the carrier or remove the services. If you
                really need to, please contact
                <a href="mailto:logisticssupport@cimpress.com">
                  logisticssupport@cimpress.com
                </a>
              </Trans>
            }
          />
        </div>
      )}
    </Modal>
  )
}

function getInitialEditCalendarValidationStatus(): EditCalendarValidation {
  return {
    name: {
      fieldName: 'name',
      status: ValidationStatus.NotValidated,
    },
    carrierServices: {
      fieldName: 'carrierServices',
      status: ValidationStatus.NotValidated,
    },
  }
}
