import * as React from 'react'
import * as models from '../common/models'
import { getEditable } from '../common/proxy/fulfillment-locations-store'
import { getAllLocations } from '../common/proxy/locations-store'
import * as FulfillmentLocationPicker from './FulfillmentLocationPicker'

interface Props {
  name: string
  required: boolean
  selectReference: React.RefObject<any>
  disabled?: boolean
  loadingText: string
  onChange(fulfillmentLocation?: models.FulfillmentLocation): void
}

export default function FulfillmentLocationPickerContainer(props: Props) {
  const [availableFLs, setAvailableFLs] = React.useState<
    models.FulfillmentLocation[]
  >()
  const [usedFLs, setUsedFLs] = React.useState<models.FulfillmentLocation[]>([])

  React.useEffect(() => {
    let isMounted = true
    const fetch = async () => {
      const [editableFLs, allLocations] = await Promise.all([
        getEditable(true),
        getAllLocations(),
      ])
      if (isMounted) {
        const allUsedFLs: models.FulfillmentLocation[] = []
        Array.from(allLocations.values()).forEach(location =>
          allUsedFLs.push(...location.fulfillmentLocations)
        )
        const usedFLIds = allUsedFLs.map(fl => fl.id)
        const allAvailableFLs = editableFLs.filter(
          fl => usedFLIds.indexOf(fl.id) === -1
        )
        setAvailableFLs(allAvailableFLs)
        setUsedFLs(allUsedFLs)
      }
    }

    fetch()

    return () => {
      isMounted = false
    }
  }, [])
  if (availableFLs) {
    return (
      <FulfillmentLocationPicker.Loaded
        name={props.name}
        availableFLs={availableFLs}
        usedFLs={usedFLs}
        onChange={props.onChange}
        required={props.required}
        selectReference={props.selectReference}
        disabled={props.disabled}
      />
    )
  }

  return <FulfillmentLocationPicker.Loading value={props.loadingText} />
}
