import { Alert, Button } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Preloader from '../../../common/components/Preloader'
import { ShippingConsoleStatus } from '../../../common/models'

interface Props {
  status?: ShippingConsoleStatus
  enabling: boolean
  onClick: () => void
}

export default function ShippingConsoleView(props: Props) {
  const { t } = useTranslation()

  if (props.status === undefined) {
    return (
      <Wrapper>
        <Preloader small={true} />
      </Wrapper>
    )
  }

  const content =
    props.status === 'not-configured' ? (
      <>
        <Button
          type="primary"
          onClick={props.onClick}
          disabled={props.enabling}
        >
          {props.enabling
            ? t('locations.viewLocationPage.enableShippingConsoleInProgress')
            : t('locations.viewLocationPage.enableShippingConsole')}
        </Button>
      </>
    ) : (
      <>
        {props.status === 'configured-missing-carrier-account' && (
          <Alert
            type="info"
            dismissible={false}
            message={t(
              'locations.viewLocationPage.shippingConsoleMissingCarrierAccount'
            )}
          />
        )}
        <i
          className="fa fa-check-square-o fa-lg text-success"
          style={{ verticalAlign: 'middle', marginBottom: '4px' }}
        />{' '}
        {t('locations.viewLocationPage.enabled')}
      </>
    )

  return <Wrapper>{content}</Wrapper>
}

function Wrapper(props: { children: React.ReactNode }) {
  const { t } = useTranslation()

  return (
    <div
      className="card card-minimal"
      style={{ marginTop: '20px', marginBottom: '20px' }}
    >
      <div className="card-header">
        <h5 className="card-title">
          {t('locations.viewLocationPage.shippingConsole')}
        </h5>
      </div>
      <div className={`card-block`}>{props.children}</div>
    </div>
  )
}
