import { LogisticsNetworkWithLinks } from '@cimpress-technology/logistics-configuration-client/js/models/locations'
import { colors } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Preloader from '../../common/components/Preloader'
import { guessLogoFromCarrierServiceKey } from '../../common/helpers/carrier-logos'
import {
  CarrierServiceOtc,
  ShipmentsPerCarrierService,
} from '../../common/proxy/cimba-models'
import {
  getCarrierServiceOtcMap,
  getShipmentsPerCarrierService,
} from '../../common/proxy/cimba-store'
import styles from './TopCarriers.module.css'

export interface Props {
  network: LogisticsNetworkWithLinks
  loading: boolean
}

type Carrier = ShipmentsPerCarrierService & CarrierServiceOtc

export function TopCarriers(props: Props) {
  const { t } = useTranslation()
  const [carriers, setCarrierData] = React.useState<Carrier[]>([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const data = await getShipmentsPerCarrierService({
        networkId: props.network.id,
      })
      const otc = await getCarrierServiceOtcMap({
        networkId: props.network.id,
      })
      setCarrierData(
        data
          .sort((a, b) => b.shipments - a.shipments)
          .slice(0, 3)
          .map(c => ({
            ...c,
            ...(otc.get(c.carrierServiceKey) || {
              otc: 'NA',
              carrierKey: 'NA',
            }),
          }))
      )
      setLoading(false)
    }
    fetchData()
  }, [props.network])

  const rows = loading ? (
    <Preloader />
  ) : (
    <ul className="list-group">
      {carriers.map(c => (
        <CarrierRow key={c.carrierServiceKey} carrier={c} />
      ))}
    </ul>
  )

  return (
    <div className={`card card-minimal ${styles.marginBottomFull}`}>
      <div className="card-header">
        <h5 className="card-title">{t('networks.top3CarrierServices')}</h5>
      </div>
      <div className={`${styles.topCarriers} card-block`}>{rows}</div>
    </div>
  )
}

function CarrierRow(props: { carrier: Carrier }) {
  const { t } = useTranslation()

  const logoSrc = guessLogoFromCarrierServiceKey(
    props.carrier.carrierServiceKey
  )

  const logo = (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/carrier-logos/${logoSrc})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '40px',
        backgroundColor: colors.white,
        height: '42px',
        width: '42px',
        borderRadius: '50%',
        border: '1px solid var(--alloy)',
      }}
    />
  )

  return (
    <li className="list-group-item">
      <div className="row">
        <div className="col-xs-1">{logo}</div>
        <div className={`col-xs-7 ${styles.value} ${styles.paddingTop}`}>
          {props.carrier.carrierServiceName}
        </div>
        <div className={`col-xs-2 ${styles.textAlignCenter}`}>
          <span className={`${styles.value} ${styles.bold}`}>
            {props.carrier.shipments}
          </span>
          <div className={`${styles.title}`}>
            {t('networks.shippingOverview.shipments')}
          </div>
        </div>
        <div className={`col-xs-2 ${styles.textAlignCenter}`}>
          <span className={`${styles.value} ${styles.bold}`}>
            {props.carrier.otc}
          </span>
          <div className={`${styles.title}`}>
            {t('networks.shippingOverview.onTimetoCustomer.abbr')}
          </div>
        </div>
      </div>
    </li>
  )
}
