import * as React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import IconCalendar from '@cimpress-technology/react-streamline-icons/lib/IconCalendar'
import IconMultipleUsers from '@cimpress-technology/react-streamline-icons/lib/IconMultipleUsers'
import IconShipmentBox from '@cimpress-technology/react-streamline-icons/lib/IconShipmentBox'
import IconSearch from '@cimpress-technology/react-streamline-icons/lib/IconSearch'
import IconCogDouble from '@cimpress-technology/react-streamline-icons/lib/IconCogDouble'
import { AdvancedUserContainer } from '../../../common/components/AdvancedUserContainer'
import { useLogisticsLocation } from '../../LocationContext'
import NavigationLink from './NavigationLink'
import styles from './ConfigureCard.module.css'
import QcmLink from './QcmLink'

export default function ConfigureCard() {
  const { t } = useTranslation()

  const match = useRouteMatch()

  const { logisticsLocation } = useLogisticsLocation()

  return (
    <div className="card card-minimal" style={{ marginBottom: '20px' }}>
      <div className="card-header">
        <h5 className="card-title">
          {t('locations.viewLocationPage.configuration')}
        </h5>
      </div>
      <div className={`${styles.configuration} card-block`}>
        <ul className="list-group">
          <NavigationLink
            url={`${match.url}/calendars`}
            text={t('common.calendars.calendars')}
            icon={IconCalendar}
          />
          <NavigationLink
            url={`${match.url}/user-management`}
            text={t('userManagement.userManagement')}
            icon={IconMultipleUsers}
          />
          <li key={'qcm'} className="list-group-item">
            <QcmLink />
          </li>
          <NavigationLink
            url={`${match.url}/packing`}
            text={t('packing.packing')}
            icon={IconShipmentBox}
          />
          <AdvancedUserContainer>
            <NavigationLink
              url={`/audits/logistics-location/${logisticsLocation.id}`}
              text={t('common.history')}
              icon={IconSearch}
            />
          </AdvancedUserContainer>
          <AdvancedUserContainer>
            <NavigationLink
              url={`${match.url}/advanced-overview`}
              text="Advanced Overview"
              icon={IconCogDouble}
            />
          </AdvancedUserContainer>
        </ul>
      </div>
    </div>
  )
}
