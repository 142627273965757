import * as React from 'react'
import {
  Breadcrumbs,
  BreadcrumbsItem,
  BreadcrumbsItemProps,
} from 'react-breadcrumbs-dynamic'
import { Switch } from 'react-router'
import { Link, Route } from 'react-router-dom'
import AuditPage from '../../audits/components/AuditPage'
import CarrierExplorerPageContainer from '../../carrier-explorer/CarrierExplorerPageContainer'
import AddLocationPageContainer from '../../locations/components/AddLocationPageContainer'
import LocationMigrationStatusContainer from '../../locations/components/location-migration-status-page/LocationMigrationStatusContainer'
import SelectLocationPageContainer from '../../locations/components/select-location-page/SelectLocationPageContainer'
import { useLogisticsLocation } from '../../locations/LocationContext'
import LocationPageContainer from '../../locations/LocationPageContainer'
import ReturnAddressesContainer from '../../networks/ReturnAddressesContainer'
import ViewNetworkPage from '../../networks/ViewNetworkPage'
import ContactUsPage from '../ContactUsPage'
import FourOhFourPage from '../FourOhFourPage'
import { withTracker } from '../helpers/googleAnalytics'
import { useAppContext } from '../AppContext'
import { AdvancedUserContainer } from './AdvancedUserContainer'
import { BreadcrumbsTitle } from './Title'

const selectLocationPage = withTracker(SelectLocationPageContainer)
const locationMigrationStatusPage = withTracker(
  LocationMigrationStatusContainer
)
const addLocationPage = withTracker(AddLocationPageContainer)
const viewLocationPage = withTracker(LocationPageContainer)
const carrierExplorerPage = withTracker(CarrierExplorerPageContainer)
const fourOhFourPage = withTracker(FourOhFourPage)
const contactUsPage = withTracker(ContactUsPage)
const viewNetworkPage = withTracker(ViewNetworkPage)
const returnAddressPage = withTracker(ReturnAddressesContainer)

const OurBreadCrumbs = Breadcrumbs as any
// this sucks, but the types from react-breadcrumbs-dynamic don't expose compare prop

function breadCrumbCompare(a: BreadcrumbsItemProps, b: BreadcrumbsItemProps) {
  if (a.to === '#') {
    return 1
  }
  if (b.to === '#') {
    return -1
  }

  return a.to.length - b.to.length
}

export function MainRouter() {
  const { containerType } = useAppContext()

  return (
    <>
      <div className={containerType}>
        <div className="row">
          <OurBreadCrumbs
            item={Breadcrumb}
            container={'ol'}
            containerProps={{ className: 'breadcrumb' }}
            compare={breadCrumbCompare}
          />
          <BreadcrumbsTitle compare={breadCrumbCompare} />
        </div>
      </div>
      <Switch>
        <Route
          path="/migration-status"
          component={locationMigrationStatusPage}
        />
        <Route path="/location/add" component={addLocationPage} />
        <Route path="/location/:locationId" component={viewLocationPage} />
        <Route
          path="/network/:networkId/return-addresses"
          component={returnAddressPage}
        />
        <Route path="/network/:networkId" component={viewNetworkPage} />
        <Route
          exact={true}
          path="/carrier-explorer"
          component={carrierExplorerPage}
        />
        <Route
          path="/carrier-explorer/:carrierServiceKey"
          component={carrierExplorerPage}
        />
        <Route path="/contact-us" component={contactUsPage} />
        <Route path="/audits/:resourceType/:resourceId">
          <AuditPage />
        </Route>
        <Route path="/" component={selectLocationPage} />

        <Route component={fourOhFourPage} />
      </Switch>
    </>
  )
}

const Breadcrumb = (props: { to: string; children: any }) => {
  return !['#'].includes(props.to) ? (
    <li key={props.to}>
      <Link to={props.to}>{props.children}</Link>
    </li>
  ) : (
    <li key={props.children}>{props.children}</li>
  )
}

export const NetworkBreadCrumbItem = () => {
  const { logisticsLocation } = useLogisticsLocation()
  if (
    logisticsLocation._links &&
    logisticsLocation._links.network &&
    logisticsLocation.networkIsAccessible
  ) {
    return (
      <AdvancedUserContainer>
        <BreadcrumbsItem to={`/network/${logisticsLocation._links.network.id}`}>
          {logisticsLocation._links.network.name}
        </BreadcrumbsItem>
      </AdvancedUserContainer>
    )
  }

  return <></>
}
