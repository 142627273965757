import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import IconCalendar from '@cimpress-technology/react-streamline-icons/lib/IconCalendar'
import IconMultipleUsers from '@cimpress-technology/react-streamline-icons/lib/IconMultipleUsers'
import IconTruckConfigure from '@cimpress-technology/react-streamline-icons/lib/IconTruckConfigure'
import { AdvancedUserContainer } from '../common/components/AdvancedUserContainer'
import OpenInNewTab from '../common/components/OpenInNewTab'
import NavigationLink from '../locations/components/view-location-page/NavigationLink'
import LinkIcon from '../locations/components/view-location-page/LinkIcon'
import styles from './ViewNetworkPage.module.css'

export default function NetworkTools() {
  const match = useRouteMatch()
  const { t } = useTranslation()

  return (
    <div className={`card card-minimal ${styles.marginBottomFull}`}>
      <div className="card-header">
        <h5 className="card-title">{t('networks.links.title')}</h5>
      </div>
      <div className={`${styles.configuration} card-block`}>
        <ul className="list-group">
          <AdvancedUserContainer>
            <NavigationLink
              url={`${match.url}/calendars/transit`}
              text={t('common.calendars.calendars')}
              icon={IconCalendar}
            />
          </AdvancedUserContainer>
          <NavigationLink
            url={`${match.url}/user-management`}
            text={t('userManagement.userManagement')}
            icon={IconMultipleUsers}
          />
          <li className="list-group-item">
            <QcmLink />
          </li>
        </ul>
      </div>
    </div>
  )
}

function QcmLink() {
  const match = useRouteMatch()
  const { t } = useTranslation()

  const href = match.url.endsWith('MB76ESUoK')
    ? 'https://qcm.qp.cimpress.io/ui/fulfillmentLocations/base_pixart-3pf'
    : '/in-progress'

  return (
    <OpenInNewTab href={href}>
      <LinkIcon icon={IconTruckConfigure} />
      {t('networks.links.carrierContractsManagement')}
    </OpenInNewTab>
  )
}
