import SumoLogger from 'sumo-logger'
import * as _ from 'underscore'
import auth from './auth'

// type definition requires onSuccess and onError callbacks, but they are not mandatory
const config: any = {
  endpoint:
    'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV388IXIjyoe7ywl59lAHhLO571-En_zc_ibY0hENjJV5sBwnUAu-W0U7dBTbyT-WA71PMoejmBO7k9K-aZL1eVNQH5ZrI2XwCqT6zaa19OMXA==',
  sourceName: 'sapidus_ui_production',
  sourceCategory: '263302/CT/Sapidus/ui/production',
}
const logger = new SumoLogger(config)
const sendError = process.env.NODE_ENV === 'production'

if (sendError) {
  const onerror = window.onerror
  // necessary for apply with arguments
  // tslint:disable-next-line:only-arrow-functions
  window.onerror = function (message, url, line, column) {
    logger.log({
      user: _.pick(auth.getProfile(), 'email', 'sub'),
      level: 50,
      file: url,
      url: window.location.href,
      message,
      line,
      column,
      err: 'BrowserJsException',
    })

    if (onerror && typeof onerror === 'function') {
      // eslint-disable-next-line prefer-rest-params
      onerror.apply(window, arguments)
    }
  }

  window.addEventListener('beforeunload', () => {
    logger.flushLogs()
  })
}

export function logError(message: string, err?: any) {
  if (sendError) {
    logger.log({
      user: _.pick(auth.getProfile(), 'email', 'sub'),
      level: 50,
      url: clearSensitiveData(window.location.href),
      error: JSON.parse(clearSensitiveData(JSON.stringify(err))),
      message,
      err: 'BrowserJsException',
    })
  } else {
    // tslint:disable:no-console
    console.log(message, err)
  }
}

export function clearSensitiveData(data: string) {
  function replacer(match: string, p1: string) {
    return p1 + 'REDACTED'
  }

  return data
    .replace(/(Bearer )[A-Za-z0-9=_.-]*/gi, replacer)
    .replace(/(access_token=)[A-Za-z0-9=_.-]*/gi, replacer)
}
