import * as jsonPatch from 'fast-json-patch'
import auth from '../auth'
import { ShippingConsoleStatus } from '../models'
import * as proxy from './backend-proxy'

export function createIncompleteCarrierAccount(
  locationId: string,
  incompleteCarrierAccount: proxy.IncompleteCarrierAccount
): Promise<string> {
  return proxy.createIncompleteCarrierAccount(
    auth.getAccessToken(),
    locationId,
    incompleteCarrierAccount
  )
}

export function updateIncompleteCarrierAccount(
  locationId: string,
  oldIncompleteCarrierAccount: proxy.IncompleteCarrierAccount,
  newIncompleteCarrierAccount: proxy.IncompleteCarrierAccount
): Promise<string> {
  return proxy.updateIncompleteCarrierAccount(
    auth.getAccessToken(),
    locationId,
    oldIncompleteCarrierAccount.id!,
    oldIncompleteCarrierAccount.etag!,
    jsonPatch.compare(oldIncompleteCarrierAccount, newIncompleteCarrierAccount)
  )
}

export function getIncompleteCarrierAccounts(
  locationId: string
): Promise<proxy.IncompleteCarrierAccount[]> {
  return proxy.getIncompleteCarrierAccounts(auth.getAccessToken(), locationId)
}

export function getIncompleteCarrierAccount(
  locationId: string,
  incompleteCarrierAccountId: string
): Promise<proxy.IncompleteCarrierAccount> {
  return proxy.getIncompleteCarrierAccount(
    auth.getAccessToken(),
    locationId,
    incompleteCarrierAccountId
  )
}

export function deleteIncompleteCarrierAccount(
  locationId: string,
  carrierAccountId: string
): Promise<string> {
  return proxy.deleteIncompleteCarrierAccount(
    auth.getAccessToken(),
    locationId,
    carrierAccountId
  )
}

export async function checkShippingConsole(
  locationId: string
): Promise<ShippingConsoleStatus> {
  try {
    const result = await proxy.checkShippingConsole(
      auth.getAccessToken(),
      locationId
    )

    return result.status as ShippingConsoleStatus
  } catch (e) {
    return 'not-configured'
  }
}

export async function enableShippingConsole(
  locationId: string
): Promise<ShippingConsoleStatus> {
  try {
    const result = await proxy.enableShippingConsole(
      auth.getAccessToken(),
      locationId
    )

    return result.status
  } catch (e) {
    return 'not-configured'
  }
}
