import * as coam from '@cimpress-technology/coam-sapidus'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import * as models from '../../common/models'
import * as networkStore from '../../common/proxy/networks-store'
import { networkRoles } from '../models'
import PermissionsEditor from './PermissionsEditor'

interface Props {
  network: models.Network
}

export default function NetworkPermissionsEditor(props: Props) {
  const { t } = useTranslation()
  const addUser = (
    email: string,
    role: locations.models.LogisticsNetworkRoles
  ) => networkStore.addUser(props.network.apiNetwork.id, email, role)
  const removeUser = (email: string) =>
    networkStore.removeUser(props.network.apiNetwork.id, email)

  return (
    <>
      <BreadcrumbsItem to={`/network/${props.network.apiNetwork.id}`}>
        {props.network.apiNetwork.name}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">
        {t('userManagement.userManagement')}
      </BreadcrumbsItem>
      <PermissionsEditor
        resourceId={props.network.apiNetwork.id}
        isResourceEditable={props.network.editable}
        resourceGroupName={`Logistics network (${props.network.apiNetwork.id})`}
        resourceType={coam.models.ResourceTypes.LogisticsNetwork}
        roles={networkRoles}
        addUser={addUser}
        removeUser={removeUser}
      />
    </>
  )
}
