import { Radio } from '@cimpress/react-components'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CountryFlag } from '../../../common/components/CountryFlag'
import { useCountryCalendarContext } from '../country/CountryCalendarContext'
import { AdvancedUserContainer } from '../../../common/components/AdvancedUserContainer'
import ContextMenu from '../../../common/components/ContextMenu'
import ContextMenuItem from '../../../common/components/ContextMenuItem'
import styles from './CountryCalendarLegendRow.module.css'

interface Props {
  country: { label: string; value: string }
}

export default function CountryCalendarLegendRow(props: Props) {
  const { t } = useTranslation()
  const context = useCountryCalendarContext()
  const history = useHistory()

  const onChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    countryCode: string
  ) => {
    event.preventDefault()
    await context.changeSelectedCountry(countryCode)
  }

  const checked = context.selectedCountry === props.country.value

  const labelContent = (
    <>
      <CountryFlag countryCode={props.country.value} /> {props.country.label}
    </>
  )

  const label = checked ? (
    <span style={{ fontWeight: 600 }}>{labelContent}</span>
  ) : (
    labelContent
  )

  const calendar = context.countryCalendarMapping[props.country.value]

  const goToHistory = () => {
    history.push(`/audits/calendar/${calendar.id}`)
  }

  return (
    <div className={`flex ${styles.rowHeight}`}>
      <Radio
        onCheck={onChange}
        checked={checked}
        value={props.country.value}
        className={styles.row}
        label={label}
      />
      {!calendar.default && (
        <AdvancedUserContainer enableTooltip={true}>
          <div style={{ marginTop: '14px', marginBottom: '10px' }}>
            <ContextMenu>
              <ContextMenuItem
                onClick={goToHistory}
                label={t('common.history')}
              />
            </ContextMenu>
          </div>
        </AdvancedUserContainer>
      )}
      <div className={styles.rowScrollbar} />
    </div>
  )
}
