import * as coam from '@cimpress-technology/coam-sapidus'
import {
  calendars,
  locations,
} from '@cimpress-technology/logistics-configuration-client'
import * as jsonPatch from 'fast-json-patch'
import * as uuid from 'uuid'
import auth, { bearerToken } from '../auth'
import { clone } from '../helpers/clone'
import * as models from '../models'
import * as calendarsStore from './calendars-store'
import { apiFLsToFLs, getAll as getFLs } from './fulfillment-locations-store'

const tagsEnabledFlag = 'enable-tags-in-pickup-calendar'
const showFulfillerOverviewFlag = 'show-fulfiller-overview-page'
const showTransitCalendar = 'use-transit-calendars-in-quoter'
const showCountryCalendar = 'use-country-calendars-in-quoter'

async function apiLocationsToLocations(
  apiLocations: Omit<locations.models.LocationWithLinks, 'countryCalendars'>[]
): Promise<Map<string, models.LightLocation>>
async function apiLocationsToLocations(
  apiLocations: locations.models.LocationWithLinks[]
): Promise<Map<string, models.Location>> {
  const [allFLs, allowedToEdit, accessibleNetworks] = await Promise.all([
    getFLs(),
    coam.auth.getAllowedIds(
      coam.models.ResourceTypes.LogisticsLocation,
      auth.getProfile().sub,
      coam.models.LLPermissions.Update,
      bearerToken(),
      auth.getProfile().sub
    ),
    coam.auth.getAllowedIds(
      coam.models.ResourceTypes.LogisticsNetwork,
      auth.getProfile().sub,
      coam.models.LogisticsNetworkPermissions.Read,
      bearerToken(),
      auth.getProfile().sub
    ),
  ])

  const allLocations = new Map<string, models.Location>()
  apiLocations.forEach(apiLocation => {
    const location = {
      ...apiLocation,
      fulfillmentLocations: apiFLsToFLs(
        apiLocation.fulfillmentLocations,
        allFLs
      ),
      editable:
        allowedToEdit === 'all' || allowedToEdit.includes(apiLocation.id),
      tagsEnabled: false,
      showFulfillerOverview: false,
      showTransitCalendar: false,
      showCountryCalendar: false,
      ...(apiLocation._links &&
        apiLocation._links.network && {
          networkIsAccessible:
            accessibleNetworks === 'all' ||
            accessibleNetworks.includes(apiLocation._links.network.id),
        }),
    }

    allLocations.set(apiLocation.id, location)
  })

  return allLocations
}

export const getAllLocations = async (): Promise<
  Map<string, models.Location>
> => {
  return apiLocationsToLocations(
    await locations.getLocations(bearerToken(), uuid.v4())
  )
}

export const getAllLightLocations = async (): Promise<
  Map<string, models.LightLocation>
> => {
  return apiLocationsToLocations(
    await locations.getLocations(bearerToken(), uuid.v4(), {
      excludes: 'countryCalendars',
    })
  )
}

export const getLocation = async (
  id: string
): Promise<models.Location | undefined> => {
  const correlationId = uuid.v4()

  const [apiLocation, flags] = await Promise.all([
    locations.getLocation(bearerToken(), correlationId, id),
    locations.getAllFlagsByLocation(bearerToken(), correlationId, id),
  ] as const)
  if (!apiLocation) {
    return undefined
  }

  const location = (await apiLocationsToLocations([apiLocation])).get(
    apiLocation.id
  )!
  location.tagsEnabled = flags[tagsEnabledFlag]
    ? flags[tagsEnabledFlag].value
    : false
  location.showFulfillerOverview = flags[showFulfillerOverviewFlag]
    ? flags[showFulfillerOverviewFlag].value
    : false
  location.showTransitCalendar = flags[showTransitCalendar]
    ? flags[showTransitCalendar].value
    : false

  location.showCountryCalendar = flags[showCountryCalendar]
    ? flags[showCountryCalendar].value
    : false

  return location
}

function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined
}

export const getMultipleLocations = async (ids: string[]) => {
  const locationPromises = []

  for (const id of ids) {
    locationPromises.push(locations.getLocation(bearerToken(), uuid.v4(), id))
  }

  const apiLocations = await Promise.all(locationPromises)

  return apiLocations.filter(notUndefined)
}

export const updateLocation = async (
  id: string,
  etag: string,
  patch: jsonPatch.Operation[],
  correlationId?: string
): Promise<void> => {
  await locations.updateLocation(
    bearerToken(),
    correlationId || uuid.v4(),
    id,
    etag,
    patch
  )
}

export const createLocation = async (
  location: models.Location
): Promise<string> => {
  const accessToken = bearerToken()
  const correlationId = uuid.v4()
  const locationId = await locations.createLocation(
    accessToken,
    correlationId,
    mapLocationToApiLocation(location)
  )

  return locationId
}

export async function addUser(locationId: string, email: string, role: string) {
  await locations.addUser(bearerToken(), uuid.v4(), locationId, email, role)
}

export async function removeUser(locationId: string, email: string) {
  await locations.removeUser(bearerToken(), uuid.v4(), locationId, email)
}

export function addLocationToNetwork(locationId: string, networkId: string) {
  return patchNetwork(networkId, network =>
    network.logisticsLocationIds.push(locationId)
  )
}

export const getAllNetworks = async (): Promise<models.Network[]> => {
  const [networks, allowedToEdit] = await Promise.all([
    locations.getLogisticsNetworks(bearerToken(), uuid.v4()),
    coam.auth.getAllowedIds(
      coam.models.ResourceTypes.LogisticsNetwork,
      auth.getProfile().sub,
      coam.models.LLPermissions.Update,
      bearerToken(),
      auth.getProfile().sub
    ),
  ])

  return networks.map(network => ({
    apiNetwork: network,
    editable:
      allowedToEdit === 'all' || allowedToEdit.indexOf(network.id) !== -1,
  }))
}
export const getNetwork = async (
  networkId: string
): Promise<models.Network | undefined> => {
  const [network, editable] = await Promise.all([
    locations.getLogisticsNetwork(bearerToken(), uuid.v4(), networkId),
    coam.auth.isAllowed(
      networkId,
      coam.models.ResourceTypes.LogisticsNetwork,
      auth.getProfile().sub,
      coam.models.LLPermissions.Update,
      bearerToken(),
      auth.getProfile().sub
    ),
  ] as const)

  if (!network) {
    return undefined
  }

  return { apiNetwork: network, editable }
}
async function patchNetwork(
  networkId: string,
  updater: (network: locations.models.LogisticsNetwork) => void
) {
  const correlationId = uuid.v4()

  const network = (await locations.getLogisticsNetwork(
    bearerToken(),
    correlationId,
    networkId
  ))!
  const copiedNetwork: locations.models.LogisticsNetwork = clone(network)
  updater(copiedNetwork)
  const patch = jsonPatch.compare(network, copiedNetwork)
  await locations.updateLogisticsNetwork(
    bearerToken(),
    correlationId,
    copiedNetwork.id,
    copiedNetwork.etag,
    patch
  )
}

function mapLocationToApiLocation(
  location: models.Location
): locations.models.CreateLocation {
  const apiFls = location.fulfillmentLocations.map(fl => ({
    id: fl.id,
  }))

  return {
    address: location.address,
    contact: location.contact,
    localeSettings: location.localeSettings,
    name: location.name,
    fulfillmentLocations: apiFls,
    transitCalendars: location.transitCalendars,
    pickupCalendars: location.pickupCalendars,
    carrierAccounts: location.carrierAccounts,
  }
}

export async function createCountryCalendarForLocation(
  location: locations.models.Location,
  countryCalendar: calendars.models.CreateWorkingDaysCalendar,
  countryCode: string
): Promise<string> {
  const correlationId = uuid.v4()

  const countryCalendarId = await calendarsStore.createWorkingDaysCalendar(
    countryCalendar,
    correlationId
  )

  try {
    const originalLocation = clone(location)

    location.countryCalendars.mapping[countryCode] = { id: countryCalendarId }

    const patches: jsonPatch.Operation[] = jsonPatch
      .compare(originalLocation, location)
      .filter(patch => !patch.path.includes('href'))

    await updateLocation(location.id, location.etag, patches, correlationId)

    return countryCalendarId
  } catch (e) {
    await calendarsStore.deleteWorkingDaysCalendar(
      countryCalendarId,
      correlationId
    )
    throw e
  }
}
